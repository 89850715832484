var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { Grid, Box, Typography, Button, useTheme, ClickAwayListener, Popper } from '@mui/material';
import get from 'lodash.get';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import GraphAPI from 'utilities';
import { printStartEndTime, getTimestamp, getCurrentSenior,
// getExactDaysDifference,
// formatDuration,
// calculatePercentageGoals,
 } from 'globals/global.functions';
import { Card } from 'common/components/Card';
import { ActivityChart } from './ActivityChart.component';
import { activityStyle } from './Activity.style';
import globalUseStyles from 'config/global.styles';
import { InputFields } from 'common/InputFields';
import { DIALOG_TYPES } from 'globals/global.constants';
import ActivityCard from 'common/ActivityCard/ActivityCard.component';
import { ACTIVITY_CARD_VARIANT } from 'globals/enums';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { useMutation } from 'utilities/react-query';
import { getActivityGoalService, postActivityGoalService, } from 'services/activityGoalService/activityGoal.service';
import { fetchSeniorDetail, getSeniorFullName, openOverlayDialog, showError, } from 'store/commonReducer/common.action';
import { goalsStyle } from 'pages/WCPages/SeniorWellnessPlan/goals/Goals.style';
var ActivityComponent = function () {
    var _a = useAppSelector(function (state) { return state.wellnessDashboard; }), currentState = _a.currentState, startTime = _a.startTime, endTime = _a.endTime, reRender = _a.reRender;
    // const daysDifference = getExactDaysDifference(startTime, endTime);
    var classes = activityStyle().classes;
    var classesGoal = goalsStyle().classes;
    var globalClasses = globalUseStyles().classes;
    var theme = useTheme();
    var dispatch = useAppDispatch();
    var fullName = dispatch(getSeniorFullName());
    var _b = useState({
        loading: true,
        subTitle: '',
        noRecordFound: false,
    }), config = _b[0], setConfig = _b[1];
    var _c = useState([]), activityChartData = _c[0], setActivityChartData = _c[1];
    var _d = useState(0), activityScore = _d[0], setActivityScore = _d[1];
    var _e = useState(0), targetScore = _e[0], setTargetScore = _e[1];
    var _f = useState(0), activityPercentage = _f[0], setActivityPercentage = _f[1];
    // const [totalDuration, setTotalDuration] = useState<any>({
    //   targetGoalSteps: 5000,
    //   moderate: 0,
    //   intense: 0,
    //   moderateGoal: 120,
    //   intenseGoal: 20,
    //   moderatePercentage: '0',
    //   intensePercentage: '0',
    //   moderateGoalTime: '2h',
    //   intenseGoalTime: '20m'
    // });
    var _g = React.useState(null), anchorEl = _g[0], setAnchorEl = _g[1];
    var _h = useState(40000), activityGoal = _h[0], setActivityGoal = _h[1];
    var _j = useState(false), isEditActivityGoal = _j[0], setIsEditActivityGoal = _j[1];
    var getActivityGoalMutation = useMutation({
        mutationFn: function () {
            dispatch(showApplicationLoader());
            var senior = getCurrentSenior();
            return getActivityGoalService({
                senior_id: senior.seniorID,
                account_id: senior.accountID,
            });
        },
        onSuccess: function (goal) {
            setActivityGoal(goal);
            // ((prevState: any) => ({...prevState, targetGoalSteps: goal}));
            setIsEditActivityGoal(false);
            dispatch(hideApplicationLoader());
        },
        onError: function (error) {
            dispatch(hideApplicationLoader());
            dispatch(showError(error));
        },
    });
    var postActivityGoalMutation = useMutation({
        mutationFn: function (goal) { return __awaiter(void 0, void 0, Promise, function () {
            var senior, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        dispatch(showApplicationLoader());
                        senior = getCurrentSenior();
                        return [4 /*yield*/, postActivityGoalService({
                                senior_id: senior.seniorID,
                                account_id: senior.accountID,
                                goal: goal,
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error("Error in postActivityGoalService:", error_1);
                        throw error_1; // Rethrow error to trigger onError callback
                    case 3: return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () { return __awaiter(void 0, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getActivityGoalMutation.mutateAsync()];
                    case 1:
                        _a.sent();
                        getActualActivity();
                        getActivityScore();
                        dispatch(hideApplicationLoader());
                        dispatch(openOverlayDialog({
                            type: DIALOG_TYPES.SUCCESS,
                            firstMessage: "Step Goal is updated successfully for ".concat(fullName, "."),
                        }));
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.error("Error in success callbacks:", error_2);
                        dispatch(showError(error_2));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); },
        onError: function (error) {
            dispatch(hideApplicationLoader());
            dispatch(showError(error));
        },
    });
    var isSaveEditButtonDisabled = useMemo(function () {
        var endTimeMs = endTime / 1000000;
        var currentDate = Date.now();
        if (currentDate >= endTimeMs) {
            return true;
        }
        return false;
    }, [endTime]);
    useEffect(function () {
        var performActions = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(startTime && endTime && reRender)) return [3 /*break*/, 2];
                        printStartEndTime(startTime, endTime);
                        setConfig(__assign(__assign({}, config), { loading: true, noRecordFound: false, subTitle: '' }));
                        return [4 /*yield*/, getActivityGoalMutation.mutateAsync()];
                    case 1:
                        _a.sent();
                        getActivityScore();
                        getActualActivity();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        performActions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime, endTime, reRender]);
    var getActivityScore = function () {
        GraphAPI.fetchActivityScore(startTime, endTime).then(function (res) {
            var activityScore = Math.floor(get(res, "data.".concat(startTime, ".step_count"), 0));
            var activityPercentage = Math.round(get(res, 'goal_percentage', 0));
            setActivityScore(Math.floor(activityScore));
            setTargetScore(Math.floor(get(res, 'target_score', 0)));
            setActivityPercentage(Math.round(activityPercentage));
        });
    };
    var getActualActivity = function () {
        GraphAPI.fetchActivitySeries(startTime, endTime).then(function (res) {
            if (isEmpty(res)) {
                setConfig(__assign(__assign({}, config), { loading: false, noRecordFound: true }));
                return;
            }
            var high = [];
            var medium = [];
            var yDomain = [];
            var totalModerateDuration = 0;
            var totalIntenseDuration = 0;
            var totalSteps = 0;
            Object.entries(res).filter(function (data) { return startTime <= parseInt(data[0]); }).forEach(function (data) {
                // const highActivity = get(data[1], 'high_activity', 0);
                // const mediumActivity = get(data[1], 'medium_activity', 0);
                // const activityDuration = get(data[1], 'activity_duration', 0);
                var stepCount = get(data[1], 'step_count', 0) || 0;
                var xPlot = moment(getTimestamp(parseInt(data[0]))).format();
                // const yPlotHigh = (activityDuration * (highActivity / 100)) / 60;
                var yPlotMedium = stepCount;
                yDomain.push(yPlotMedium);
                high.push({ x: xPlot, y: 0 });
                // totalIntenseDuration += (activityDuration);
                medium.push({ x: xPlot, y: Math.round(yPlotMedium) });
                totalSteps += yPlotMedium;
                // totalModerateDuration += (activityDuration / 60) * (mediumActivity / 100);
            });
            // setTotalDuration((prevState: any) => ({
            //   ...prevState,
            // moderate: formatDuration(totalModerateDuration, daysDifference),
            // intense: formatDuration(totalIntenseDuration, daysDifference),
            // stepsPercenteage: Math.round((totalSteps / (daysDifference * prevState?.targetGoalSteps)) * 100),
            // moderateGoalTime: '2h',
            // intenseGoalTime: '20m',
            // totalSteps,
            // goalSteps: 40000,
            // moderatePercentage: `${calculatePercentageGoals(totalModerateDuration, 
            //   totalDuration.moderateGoal * daysDifference)}`,
            // intensePercentage: `${calculatePercentageGoals(totalIntenseDuration, 
            //   totalDuration.intenseGoal * daysDifference)}`,
            // }));
            setConfig(__assign(__assign({}, config), { loading: false, noRecordFound: totalSteps ? false : true, maxDomain: Math.max.apply(Math, yDomain) }));
            setActivityChartData([medium, high]);
        });
    };
    /**
     * @function handleClickAway
     * @description to close the progress bar overlay
     */
    var handleClickAway = function () {
        setAnchorEl(null);
    };
    /**
   * @function handleClickProgress
   * @description handle progress popup on click progress button
   * @param event
   */
    var handleClickProgress = function (event) {
        if (isEditActivityGoal) {
            setAnchorEl(event.currentTarget);
        }
    };
    var goalProgressSteps = [], lastGoalSteps = 10000;
    ;
    var initialGoalSteps = 4500, i = 0;
    while (initialGoalSteps <= lastGoalSteps) {
        var element = {
            value: initialGoalSteps,
            label: "".concat(initialGoalSteps / 1000, "k")
        };
        goalProgressSteps[i] = element;
        initialGoalSteps += 500;
        i++;
    }
    var getTitle = function () {
        switch (currentState) {
            case 'day':
                return 'Goal';
            case 'week':
                return 'Week Average';
            case 'month':
                return 'Month Average';
        }
    };
    var handleSetActivityGoal = React.useCallback(function () {
        postActivityGoalMutation.mutate(activityGoal);
    }, [activityGoal, postActivityGoalMutation]);
    useEffect(function () {
        dispatch(fetchSeniorDetail());
    }, []);
    return (_jsxs(Grid, { spacing: 2, container: true, "data-testid": 'activity-container', children: [_jsxs(Grid, { item: true, xs: 12, sm: 12, md: 4.5, flexDirection: 'column', container: true, spacing: 2, children: [_jsx(Grid, { item: true, children: _jsx(Card, { isLoading: config.loading, cardContentStyle: {
                                padding: '5px 16px 8px 16px',
                            }, children: _jsxs(Box, { display: 'flex', flexWrap: 'wrap', style: {
                                    justifyContent: 'space-between',
                                    gap: '1rem',
                                    width: '100%',
                                }, children: [_jsxs(Box, { display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '1rem', children: [_jsx(Typography, { className: classes.activityGoalLabel, children: "Set Steps Goal" }), _jsx(ClickAwayListener, { onClickAway: handleClickAway, children: _jsxs(Box, { style: { position: 'relative' }, children: [_jsx(Box, { "aria-describedby": 'progress', "data-testid": 'goalProgress', onClick: handleClickProgress, className: classesGoal.progress, style: {
                                                                backgroundColor: !isEditActivityGoal
                                                                    ? '#a7a7a7'
                                                                    : '#00a9cf',
                                                            }, children: _jsx(Typography, { children: "".concat(activityGoal || 4500) }) }), _jsx(Popper, { id: 'progress', open: Boolean(anchorEl), anchorEl: anchorEl, nonce: undefined, onResize: undefined, className: classesGoal.progressBarPos, onResizeCapture: undefined, children: _jsx(Box, { className: classesGoal.progressBarContainer, children: _jsx(InputFields, { marks: goalProgressSteps, slider: true, step: 500, valueLabelDisplay: 'auto', inputProps: {
                                                                        name: 'progressBar',
                                                                        classes: classesGoal.slider,
                                                                        dataTestid: 'progressSlider',
                                                                    }, eventProps: {
                                                                        onChange: function (e) { return setActivityGoal(e.target.value); },
                                                                        value: activityGoal,
                                                                        'data-testid': 'activity-goal-input',
                                                                    }, min: 4500, max: 10000, track: false }) }) })] }) })] }), _jsx(Button, { variant: 'contained', color: 'primary', className: globalClasses.smallButton, style: { minWidth: '120px' }, disabled: isSaveEditButtonDisabled, onClick: isEditActivityGoal
                                            ? handleSetActivityGoal
                                            : function () { return setIsEditActivityGoal(true); }, "data-testid": 'activity-goal-button', children: isEditActivityGoal ? 'Save' : 'Edit' })] }) }) }), _jsx(Grid, { item: true, children: _jsx(Card, { isLoading: config.loading, noRecordFound: config.noRecordFound, title: getTitle(), stretch: true, children: _jsx(Box, { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem', width: '100%', children: _jsx(Box, { display: 'flex', flexDirection: 'column', gap: '1rem', flexGrow: 1, children: _jsx(ActivityCard, { variant: ACTIVITY_CARD_VARIANT.STEPS, goal: "".concat(activityScore, "/").concat(targetScore), percentage: activityPercentage }) }) }) }) })] }), _jsx(Grid, { item: true, xs: 12, sm: 12, md: 7.5, children: _jsx(Card, { noRecordFound: config.noRecordFound, isLoading: config.loading, title: 'Steps', stretch: true, children: _jsx(ActivityChart, { type: currentState, activityChartData: [activityChartData[0]], currentState: currentState, maxDomain: config.maxDomain }) }) })] }));
};
export { ActivityComponent };
