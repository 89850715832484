var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { PaginationSearchContext, PaginationSearchDispatchContext, UPDATE_SEARCH_QUERY, } from './PaginationSearchContext';
import clsx from 'clsx';
import { Box, Pagination } from '@mui/material';
import get from 'lodash.get';
import { getPaginationDataIsolated } from 'store/commonReducer/common.action';
import { useRowStyles } from './PaginationContainerStyle';
import { PAGINATION_TYPE } from 'globals/enums';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
var PaginationContainer = React.memo(function (_a) {
    var _b;
    var loadingPath = _a.loadingPath, paginationType = _a.paginationType, _c = _a.withBorder, withBorder = _c === void 0 ? false : _c, className = _a.className, containerClass = _a.containerClass, _d = _a.isScrollOnTop, isScrollOnTop = _d === void 0 ? false : _d, lastEvaluatedKeyPath = _a.lastEvaluatedKeyPath, rowsPerPage = _a.rowsPerPage, tableData = _a.tableData, getData = _a.getData, _e = _a.cacheKey, cacheKey = _e === void 0 ? '' : _e, onSuccess = _a.onSuccess, onError = _a.onError, componentProps = _a.componentProps, WrappedComponent = _a.WrappedComponent, onPageChange = _a.onPageChange, _f = _a.pagePath, pagePath = _f === void 0 ? '' : _f, _g = _a.tableDataPath, tableDataPath = _g === void 0 ? '' : _g, _h = _a.searchLastEvaluatedKeyPath, searchLastEvaluatedKeyPath = _h === void 0 ? '' : _h, onSearchSuccess = _a.onSearchSuccess;
    var classes = useRowStyles().classes;
    var dispatch = useAppDispatch();
    var lastEvaluatedKey = useAppSelector(function (state) {
        return get(state, lastEvaluatedKeyPath);
    });
    var searchLastEvaluatedKey = useAppSelector(function (state) {
        return get(state, searchLastEvaluatedKeyPath);
    });
    var tablePathData = useAppSelector(function (state) {
        return get(state, tableDataPath, []);
    });
    tableData = tableDataPath ? tablePathData : tableData;
    var page = useAppSelector(function (state) { return get(state, pagePath); });
    var isDataLoading = useAppSelector(function (state) { return get(state, loadingPath); });
    var _j = React.useContext(PaginationSearchContext), filteredData = _j.filteredData, isFilterLoading = _j.isFilterLoading, searchQuery = _j.searchQuery;
    var startRange = page * rowsPerPage;
    var rows = filteredData
        ? filteredData.slice(startRange - rowsPerPage, startRange)
        : tableData === null || tableData === void 0 ? void 0 : tableData.slice(startRange - rowsPerPage, startRange);
    /**
     * @description get total number of pages
     */
    var totalPages = React.useCallback(function () {
        return Math.ceil((filteredData ? filteredData.length : tableData === null || tableData === void 0 ? void 0 : tableData.length) / rowsPerPage);
    }, [tableData, rowsPerPage, filteredData]);
    var contextDispatch = React.useContext(PaginationSearchDispatchContext);
    var fetchMoreRecords = React.useCallback(function (currentPage) {
        //Run only when user is on last page and need to fetch more data from DB
        if (currentPage === totalPages() &&
            lastEvaluatedKey &&
            searchQuery !== null) {
            dispatch(getPaginationDataIsolated(getData, rowsPerPage, cacheKey, currentPage, onSuccess, onError, tableData, searchQuery ? searchLastEvaluatedKey : lastEvaluatedKey, searchQuery, onSearchSuccess));
        }
    }, [
        page,
        lastEvaluatedKey,
        cacheKey,
        totalPages,
        dispatch,
        searchQuery,
        searchLastEvaluatedKey,
    ]);
    var handleChange = function (event, value) {
        if (searchQuery === null) {
            contextDispatch({ type: UPDATE_SEARCH_QUERY, searchQuery: '' });
        }
        dispatch(onPageChange(value));
        fetchMoreRecords(value);
    };
    var modifiedComponentProps = React.useCallback(function () {
        return __assign(__assign({}, componentProps), { currentPage: page, isDataLoading: isDataLoading, data: __spreadArray([], rows, true), isFilterLoading: isFilterLoading, isSearch: Boolean(searchQuery || filteredData) });
    }, [page, isDataLoading, rows, isFilterLoading, componentProps]);
    //if there is no data on page then move to previous page
    React.useEffect(function () {
        var isSearch = Boolean(searchQuery || filteredData);
        if (page !== 1 && rows.length === 0) {
            if (isSearch) {
                dispatch(onPageChange(1));
            }
            else {
                dispatch(onPageChange(page - 1));
            }
        }
    }, [
        page,
        rows,
        dispatch,
        contextDispatch,
        onPageChange,
        searchQuery,
        filteredData,
    ]);
    React.useEffect(function () {
        if (isScrollOnTop) {
            var tableContainer = document.getElementById('table-container');
            if (tableContainer) {
                tableContainer.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls only the table
            }
            else {
                window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls the whole page if table doesn't exist
            }
        }
    }, [page, isScrollOnTop]);
    var renderPagination = function () {
        var _a;
        if (paginationType === PAGINATION_TYPE.SECONDARY) {
            return (_jsx(Pagination, { className: clsx((_a = {},
                    _a[classes.root] = true,
                    _a[classes.secondary] = !withBorder,
                    _a[classes.secondaryWithBorder] = withBorder,
                    _a)), count: totalPages(), page: page, onChange: handleChange, showFirstButton: true, showLastButton: true }));
        }
        return (_jsx(Pagination, { className: className, count: totalPages(), page: page, onChange: handleChange, showFirstButton: true, showLastButton: true }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { "data-testid": 'PaginationContainer', display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between', className: clsx(containerClass, (_b = {}, _b[classes.border] = withBorder, _b)), children: _jsx(WrappedComponent, __assign({}, modifiedComponentProps())) }), rows.length !== 0 && renderPagination()] }));
});
export default PaginationContainer;
