var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ResidentDetailsCardStyle } from './ResidentDetailsCard.style';
import HeartRate from 'assets/icons/ResidentsHeart.svg';
import BodyHealth from 'assets/icons/ResidentsHealth.svg';
import Sleep from 'assets/icons/ResidentsSleep.svg';
import Activity from 'assets/icons/ResidentsActivity.svg';
import clsx from 'clsx';
import { DASHBOARD_VITALS_TYPE, VITAL_CONDITION_CLASSNAMES } from 'globals/enums';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { residentSubPages } from 'globals/global.constants';
var labelData = (_a = {},
    _a[DASHBOARD_VITALS_TYPE.WELLNESS] = {
        icon: HeartRate,
        alt: 'Heart',
        cardTitle: 'Heart Rate',
        initialExpand: 'heart_rate',
    },
    _a[DASHBOARD_VITALS_TYPE.ACTIVITY] = {
        icon: Activity,
        alt: 'Activity',
        cardTitle: 'Activity',
        initialExpand: 'activity',
    },
    _a[DASHBOARD_VITALS_TYPE.SLEEP] = {
        icon: Sleep,
        alt: 'Sleep',
        cardTitle: 'Sleep',
        initialExpand: 'sleep',
    },
    _a[DASHBOARD_VITALS_TYPE.HEALTH] = {
        icon: BodyHealth,
        alt: 'Weight',
        cardTitle: 'Weight',
        initialExpand: 'body_health',
    },
    _a[DASHBOARD_VITALS_TYPE.WELLBEING] = {
        icon: BodyHealth,
        alt: 'Wellbeing',
        cardTitle: 'Wellbeing',
    },
    _a[DASHBOARD_VITALS_TYPE.HYDRATION] = {
        icon: BodyHealth,
        alt: 'Hydration',
        cardTitle: 'Hydration',
        initialExpand: 'body_health',
    },
    _a);
var ResidentDetailsCard = function (props) {
    var _a, _b, _c;
    var cardType = props.cardType, activityTitle = props.activityTitle, condition = props.condition, activityValue = props.activityValue, onClick = props.onClick, isResident = props.isResident;
    var classes = ResidentDetailsCardStyle().classes;
    var navigate = useNavigate();
    var handleClickView = React.useCallback(function () {
        var _a;
        var initialExpand = ((_a = labelData[cardType]) === null || _a === void 0 ? void 0 : _a.initialExpand) || '';
        var path = "".concat(residentSubPages.wellnessData.path, "?initialExpand=").concat(initialExpand);
        navigate(path, {
            relative: 'route',
        });
    }, [cardType, navigate]);
    return (_jsxs(Box, { className: classes.userCardOuterContainer, "data-testid": 'user-data-card', children: [_jsxs(Box, { className: classes.userCardContainer, children: [_jsxs(Box, { display: 'flex', className: classes.titleContainer, children: [_jsx("img", { alt: (_a = labelData[cardType]) === null || _a === void 0 ? void 0 : _a.cardTitle, src: (_b = labelData[cardType]) === null || _b === void 0 ? void 0 : _b.icon, className: classes.icon }), _jsxs(Typography, { variant: 'boldHeading', className: classes.title, children: ["\u00A0", (_c = labelData[cardType]) === null || _c === void 0 ? void 0 : _c.cardTitle] })] }), _jsx(Box, { display: 'flex', className: clsx(classes.conditionContainer, VITAL_CONDITION_CLASSNAMES[condition], { isResident: isResident }), children: _jsx(Typography, { className: classes.condition, children: condition.split('_').join(' ').toUpperCase() }) }), _jsxs(Box, { display: 'flex', children: [_jsxs(Typography, { className: classes.valueTitle, children: [activityTitle, ":\u00A0"] }), _jsx(Typography, { className: classes.value, children: activityValue })] })] }), _jsx(Box, { display: 'flex', className: classes.view, onClick: onClick || handleClickView, children: _jsx(Typography, { variant: 'body1', children: "View" }) })] }));
};
export default ResidentDetailsCard;
