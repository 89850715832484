import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { VictoryChart, VictoryLegend, VictoryAxis, VictoryStack, VictoryBar, VictoryLabel, VictoryVoronoiContainer, } from 'victory';
import moment from 'moment-timezone';
import { DATE_FORMAT_SHORT } from 'globals/global.constants';
import { Box, useTheme } from '@mui/material';
var ActivityChart = function (_a) {
    var activityChartData = _a.activityChartData, currentState = _a.currentState, maxDomain = _a.maxDomain;
    var theme = useTheme();
    var _b = useState(null), activeBar = _b[0], setActiveBar = _b[1]; // Track the active bar
    var legendChartData = [
        {
            name: 'Steps',
            symbol: { fill: theme.palette.customColor.moderateGreen, type: 'square' },
        },
        // {
        //   name: 'Intense',
        //   symbol: {fill: theme.palette.customColor.intenseGreen, type: 'square'},
        // },
    ];
    return (_jsx(Box, { mt: -5, mb: -5, children: _jsxs(VictoryChart, { domainPadding: { x: activityChartData[0].length > 2 ? 50 : 500, y: 20 }, maxDomain: { y: currentState == 'day' ? maxDomain + 10 : maxDomain + 30 }, width: 1000, height: 435, containerComponent: _jsx(VictoryVoronoiContainer, { activateLabels: true, labels: function (data) {
                    return data;
                }, labelComponent: _jsx(Tooltip, { data: activityChartData, currentState: currentState, activeBar: activeBar }) }), children: [_jsx(VictoryLegend, { orientation: 'horizontal', x: 350, y: 5, data: legendChartData }), _jsx(VictoryAxis, { orientation: 'bottom', style: {
                        axis: {
                            stroke: theme.palette.customColor.borderGrey, //CHANGE COLOR OF Y-AXIS
                        },
                    }, tickFormat: function (data) {
                        return moment(data).format(currentState == 'day' ? 'LT' : DATE_FORMAT_SHORT);
                    }, fixLabelOverlap: true }), _jsx(VictoryAxis, { orientation: 'right', dependentAxis: true, style: {
                        axis: {
                            stroke: theme.palette.customColor.borderGrey, //CHANGE COLOR OF Y-AXIS
                        },
                    }, 
                    //tickValues={currentState == 'day' ? [] : yTicks}
                    minDomain: { y: 0 }, maxDomain: { y: 40 }, 
                    // tickFormat={(data, i) => {
                    //   // return data;
                    //   return currentState == 'day' ? data : `${roundOff(data / 60)}`;
                    // }}
                    label: "Steps", axisLabelComponent: _jsx(VictoryLabel, { angle: 0, dy: -200, dx: -20, style: {
                            fontWeight: 600,
                            fontSize: 15,
                            fill: theme.palette.customColor.titleBlack,
                        } }) }), _jsx(VictoryStack, { colorScale: [
                        theme.palette.customColor.moderateGreen,
                        theme.palette.customColor.intenseGreen,
                    ], children: activityChartData.map(function (chartData) { return (_jsx(VictoryBar, { data: chartData, style: { data: chartData.length > 2 ? {} : { width: 30 } }, events: [{
                                target: "data",
                                eventHandlers: {
                                    onMouseOver: function (e, target) {
                                        // Set the active bar with the specific bar's data
                                        setActiveBar(target.datum);
                                    },
                                    onMouseOut: function () {
                                        setActiveBar(null); // Reset activeBar on mouse out
                                    }
                                }
                            }
                        ] }, chartData.x + chartData.y)); }) })] }) }));
};
var Tooltip = function (props) {
    var theme = useTheme();
    var x = props.x, activeBar = props.activeBar, currentState = props.currentState;
    var width = 140;
    if (!activeBar)
        return null;
    var date = activeBar.x; //Get data from the hovered bar's data
    var steps = activeBar.y; //Get steps from the hovered bar's data 
    return (_jsxs("g", { transform: "translate(".concat(x - 64.5, ", 0)"), children: [_jsxs("g", { transform: 'translate(0, 0)', children: [_jsx("rect", { height: 60, width: width, stroke: theme.palette.background.green, fill: theme.palette.background.green, ry: 3, rx: 3 }), _jsx("text", { dx: 5, dy: 16, alignmentBaseline: 'inherit', textAnchor: 'start', fontSize: 14, fill: theme.palette.customColor.info, children: _jsx("tspan", { children: moment(date).format(currentState == 'day' ? 'LT' : 'MMMM D') }) }), _jsx("circle", { cy: 30, cx: 10, r: 5, strokeWidth: 1, stroke: theme.palette.customColor.strokeGrey, fill: theme.palette.customColor.moderateGreen }), _jsxs("text", { dx: 20, dy: 34, alignmentBaseline: 'inherit', textAnchor: 'start', fontSize: 12, fill: theme.palette.common.black, children: [_jsx("tspan", { children: "STEPS" }), _jsx("tspan", { dx: 9, children: steps })] })] }), _jsx("g", { transform: "translate(".concat(width / 2, ", -16)"), children: _jsx("polygon", { 
                    // points='0,85 -6,76 8,76'
                    stroke: theme.palette.background.green, fill: theme.palette.background.green, strokeWidth: '1' }) })] }, 'tooltip'));
};
export { ActivityChart };
