import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Box, Table, TableBody, TableCell as MuiTableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { InputFields } from '../InputFields';
import { withStyles } from 'tss-react/mui';
import { caregiverStrainSectionStyles } from './CaregiverStrainSection.style';
import { CGAssessmentOptions } from 'globals/enums';
/**
 * @description function to get value of selected radio option
 * */
var getValue = function (radioData) {
    if (radioData.no) {
        return CGAssessmentOptions.NO;
    }
    else if (radioData.sometimes) {
        return CGAssessmentOptions.SOMETIMES;
    }
    else if (radioData.regular) {
        return CGAssessmentOptions.REGULAR;
    }
    else {
        return '';
    }
};
var TableCell = withStyles(MuiTableCell, {
    root: {
        borderBottom: 'none',
        padding: '5px 0',
    },
});
var CaregiverStrainSection = function (props) {
    var _a = props.data, data = _a === void 0 ? [] : _a, caregiverName = props.caregiverName;
    var classes = caregiverStrainSectionStyles().classes;
    return (_jsx(TableContainer, { className: classes.tableContainer, "data-testid": 'print-caregiverStrain-section', children: _jsxs(Table, { className: classes.table, children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { width: '50%', children: _jsxs(Box, { className: classes.CGNameSection, children: [_jsx(Box, { className: classes.tableHeading, style: { textAlign: 'left' }, children: "Caregiver Name:" }), _jsx(Box, { children: caregiverName })] }) }), _jsx(TableCell, { align: 'center', width: '50%', children: _jsxs(Box, { display: 'flex', justifyContent: 'space-between', fontSize: 16, gap: 3, position: 'relative', children: [_jsxs(Box, { className: classes.tableHeading, style: { minWidth: '185px' }, children: ["Yes, On a Regular Basis", ' '] }), _jsx(Box, { className: classes.tableHeading, style: { minWidth: '120px' }, children: "Yes, Sometimes" }), _jsx(Box, { className: classes.tableHeading, style: { minWidth: '50px', paddingRight: '16px' }, children: "No" })] }) })] }) }), _jsx(TableBody, { children: data.map(function (row, index) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { component: 'th', scope: 'row', children: _jsx(Box, { className: classes.tableContent, children: _jsxs(Box, { children: [index + 1, ". ", row.question, _jsx("span", { style: { color: '#cc0000' }, children: "*" }), row.example && _jsxs(Box, { children: ["Example - ", row === null || row === void 0 ? void 0 : row.example] })] }) }) }), _jsx(TableCell, { align: 'center', children: _jsx(Box, { display: 'flex', justifyContent: 'space-between', position: 'relative', children: _jsx(InputFields, { className: classes.radioGroup, radio: true, inputProps: {
                                            name: 'row.question',
                                        }, eventProps: {
                                            value: getValue(row),
                                        }, radioItems: [
                                            {
                                                value: 'regular',
                                                label: 'Yes, On a RegularBasis',
                                            },
                                            { value: 'sometimes', label: 'yes, Sometimes' },
                                            {
                                                value: 'no',
                                                label: 'No',
                                            },
                                        ] }) }) })] }, row.question)); }) })] }) }));
};
export default CaregiverStrainSection;
