var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-len */
import React, { useState } from 'react';
import moment from 'moment';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryLabel, VictoryGroup, VictoryScatter, VictoryVoronoiContainer, VictoryTooltip, VictoryLine, } from 'victory';
import get from 'lodash.get';
import { DATE_FORMAT_SHORT, DIALOG_TYPES } from 'globals/global.constants';
import { Box, Button, Grid, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { createDashedLine, getCurrentSenior, getMinMaxAxis, getTimestamp, numberRoundOff, roundOff, } from 'globals/global.functions';
import { ReactComponent as DiffArrow } from 'assets/icons/DiffArrow.svg';
import { wellnessDashboardStyle } from '../../../WellnessDashboard.style';
import { bodyHealthStyle } from '../BodyHealth.styles';
import clsx from 'clsx';
import { useMutation, useQuery } from 'utilities/react-query';
import { BODY_HEALTH_GOAL_TYPES, GraphView } from 'globals/enums';
import { getBodyHealthGoalService, postBodyHealthGoalService, } from 'services/bodyHealthGoalService/bodyHealthGoal.service';
import { useAppDispatch } from 'hooks/reduxHooks';
import { hideApplicationLoader, showApplicationLoader, } from 'common/ApplicationLoader';
import { getSeniorFullName, openOverlayDialog, showError, } from 'store/commonReducer/common.action';
import globalUseStyles from 'config/global.styles';
import { InputText } from 'common/InputFields/component/Input.component';
import ShowHyphen from 'common/ShowHyphen/ShowHyphen';
var BodyHydration = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var _h, _j, _k, _l, _m;
    var type = _a.type, summary = _a.summary, difference = _a.difference, lastRecordedDate = _a.lastRecordedDate, graphTickValues = _a.graphTickValues, reRender = _a.reRender, graph = _a.graph;
    var theme = useTheme();
    var _o = useState(null), tooltipData = _o[0], setTooltipData = _o[1];
    var classes = bodyHealthStyle().classes;
    var hydrationClasses = bodyHydrationStyle().classes;
    var globalClasses = wellnessDashboardStyle().classes;
    var appClasses = globalUseStyles().classes;
    var dispatch = useAppDispatch();
    var fullName = dispatch(getSeniorFullName());
    var _p = React.useState(false), isEdit = _p[0], setIsEdit = _p[1];
    var _q = React.useState(0), targetHydration = _q[0], setTargetHydration = _q[1];
    var _r = useQuery({
        queryKey: ['targetHydration'],
        queryFn: function () { return __awaiter(void 0, void 0, Promise, function () {
            var currentSenior, params, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentSenior = getCurrentSenior();
                        params = {
                            senior_id: currentSenior.senior_id,
                            account_id: currentSenior.account_id,
                            target_type: BODY_HEALTH_GOAL_TYPES.HYDRATION,
                        };
                        return [4 /*yield*/, getBodyHealthGoalService(params)];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); },
        onSuccess: function (data) {
            setTargetHydration(data);
        },
        cacheTime: 0,
    }), isLoading = _r.isLoading, refetchTarget = _r.refetch;
    var postTargetHydrationMutation = useMutation({
        mutationFn: function (goal) {
            dispatch(showApplicationLoader());
            var currentSenior = getCurrentSenior();
            var params = {
                senior_id: currentSenior.senior_id,
                account_id: currentSenior.account_id,
                target_type: BODY_HEALTH_GOAL_TYPES.HYDRATION,
                target_score: goal,
            };
            return postBodyHealthGoalService(params);
        },
        onSuccess: function () {
            dispatch(hideApplicationLoader());
            dispatch(openOverlayDialog({
                type: DIALOG_TYPES.SUCCESS,
                firstMessage: "Hydration Goal is updated successfully for ".concat(fullName, "."),
            }));
            reRender();
            refetchTarget();
        },
        onError: function (error) {
            setIsEdit(false);
            dispatch(hideApplicationLoader());
            dispatch(showError(error));
        },
    });
    var handleSetHydrationGoal = React.useCallback(function () {
        postTargetHydrationMutation.mutate(targetHydration);
    }, [targetHydration, postTargetHydrationMutation]);
    var hydrationDiff = 0;
    if (get(difference, 'current_percentage.hydration') &&
        get(difference, 'previous_percentage.hydration')) {
        var currentHydrationPercentage = roundOff(get(difference, 'current_percentage.hydration'));
        var previousHydrationPercentage = roundOff(get(difference, 'previous_percentage.hydration'));
        hydrationDiff = currentHydrationPercentage - previousHydrationPercentage;
    }
    var increase = hydrationDiff > 0 ? hydrationDiff : 0;
    var decrease = hydrationDiff < 0 ? -hydrationDiff : 0;
    var lowHydration = get(summary, 'extremities.percentage.min_hydration');
    var highHydration = get(summary, 'extremities.percentage.max_hydration');
    var totalHydration = [];
    var createHydrationGraph = function (arr) {
        if (!arr)
            return;
        var chart = [];
        var chart2 = [];
        if (type === GraphView.DAY) {
            arr.forEach(function (data) {
                totalHydration.push(data.hydration);
                chart.push({
                    x: moment(getTimestamp(data.time)).format(DATE_FORMAT_SHORT),
                    y: data.hydration,
                });
            });
        }
        else {
            Object.entries(arr).forEach(function (_a) {
                var _b, _c;
                var timestamp = _a[0], data = _a[1];
                totalHydration.push((_b = data === null || data === void 0 ? void 0 : data.percentage) === null || _b === void 0 ? void 0 : _b.hydration);
                chart.push({
                    x: moment(getTimestamp(timestamp)).format(DATE_FORMAT_SHORT),
                    y: (_c = data === null || data === void 0 ? void 0 : data.percentage) === null || _c === void 0 ? void 0 : _c.hydration,
                });
            });
        }
        graphTickValues.forEach(function (tick) {
            var temp = chart.find(function (x) { return x.x === tick; });
            if (temp !== undefined) {
                chart2.push(temp);
            }
            else {
                chart2.push({
                    x: tick,
                    y: null,
                });
            }
        });
        var data = chart2.find(function (ele) {
            return ele.y != null;
        });
        if (data) {
            return chart2;
        }
    };
    var victoryAreaChartData = type === GraphView.DAY
        ? createHydrationGraph(get(graph, 'hydration', []))
        : createHydrationGraph(get(summary, 'all_data'));
    var interupptionLine = createDashedLine(victoryAreaChartData);
    var dailyAvgChartConfig = {
        chart: { width: 1000 },
        label: { dx: 50, dy: 10 },
        xAxis: {
            orientation: 'bottom',
            fixLabelOverlap: true,
            //style: {tickLabels: {angle: 90}},
        },
        yAxis: {
            orientation: 'right',
            style: {
                axis: { stroke: 'none' },
            },
        },
        area: {
            style: {
                data: {
                    fill: theme.palette.common.white,
                    fillOpacity: 0.7,
                    stroke: theme.palette.customColor.intenseGreen,
                    strokeWidth: 3,
                },
                labels: { fontSize: 15, fill: theme.palette.customColor.labelRed },
            },
        },
    };
    var handleMouseOver = function (e) {
        var datum = e.datum;
        if (datum && datum.y !== null) {
            setTooltipData(datum); // Show tooltip for valid data
        }
    };
    var handleMouseOut = function () {
        setTooltipData(null); // Hide tooltip when mouse leaves
    };
    var currentAvgHydration = numberRoundOff(((_j = (_h = summary === null || summary === void 0 ? void 0 : summary.extremities) === null || _h === void 0 ? void 0 : _h.percentage) === null || _j === void 0 ? void 0 : _j.average_hydration) || 0);
    var startHydration = numberRoundOff(((_k = summary === null || summary === void 0 ? void 0 : summary.goal_percentile) === null || _k === void 0 ? void 0 : _k.start_hydration) || 0);
    var currentTargetHydration = numberRoundOff(((_l = summary === null || summary === void 0 ? void 0 : summary.goal_percentile) === null || _l === void 0 ? void 0 : _l.target_hydration) || 0);
    var hydrationPercent = numberRoundOff(((_m = summary === null || summary === void 0 ? void 0 : summary.goal_percentile) === null || _m === void 0 ? void 0 : _m.body_hydration_goal_percentage) || 0);
    var hydrationGraphPercent = hydrationPercent > 100 ? 100 : hydrationPercent < 0 ? 0 : hydrationPercent;
    return (_jsx(_Fragment, { children: _jsx(Box, { children: _jsxs(Grid, { container: true, direction: 'row', justifyContent: 'center', children: [_jsxs(Grid, { item: true, xs: 12, sm: 12, md: 4, className: hydrationClasses.bodyContainerLeft, "data-testid": 'body-hydration-graph', children: [_jsx(Box, { className: classes.headerContainer, children: _jsx(Box, { className: globalClasses.cardTitle, children: "Hydration" }) }), _jsxs(Box, { className: hydrationClasses.graphContainer, children: [_jsxs(Box, { className: hydrationClasses.tooltip, style: { left: "calc(".concat(hydrationGraphPercent, "% - 40px)") }, children: [_jsx("span", { style: { fontSize: '16px' }, children: "Current" }), _jsx("span", { style: { fontSize: '20px' }, children: _jsx(ShowHyphen, { children: !!currentAvgHydration && "".concat(currentAvgHydration, "%") }) })] }), _jsx(Box, { className: hydrationClasses.hydrationBar, children: _jsx(Box, { width: "".concat(hydrationGraphPercent, "%") }) }), _jsxs(Box, { display: 'flex', justifyContent: 'space-between', width: '100%', children: [_jsxs(Box, { children: ["Start:", ' ', _jsx(ShowHyphen, { children: !!startHydration && "".concat(startHydration, "%") })] }), _jsxs(Box, { children: ["Target:", ' ', _jsx(ShowHyphen, { children: !!currentTargetHydration && "".concat(currentTargetHydration, "%") })] })] })] }), type !== GraphView.DAY && (_jsx(Box, { display: 'flex', justifyContent: 'center', marginTop: '1rem', children: _jsxs(Box, { display: 'flex', justifyContent: 'center', gap: '1rem', width: '100%', maxWidth: '450px', children: [_jsxs(Box, { className: clsx(hydrationClasses.squareHigh, (_b = {},
                                                _b[hydrationClasses.noDataRecordColor] = lastRecordedDate,
                                                _b)), children: [_jsxs("span", { className: clsx((_c = {},
                                                        _c[hydrationClasses.squareLabel] = !lastRecordedDate,
                                                        _c[hydrationClasses.noDataRecordColor] = lastRecordedDate,
                                                        _c)), children: ["High:", ' '] }), ' ', _jsx(ShowHyphen, { children: !!highHydration && "".concat(numberRoundOff(highHydration), "%") })] }), _jsxs(Box, { className: clsx(hydrationClasses.squareLow, (_d = {},
                                                _d[hydrationClasses.noDataRecordColor] = lastRecordedDate,
                                                _d)), children: [_jsxs("span", { className: clsx((_e = {},
                                                        _e[hydrationClasses.squareLabel] = !lastRecordedDate,
                                                        _e[hydrationClasses.noDataRecordColor] = lastRecordedDate,
                                                        _e)), children: ["Low:", ' '] }), ' ', _jsx(ShowHyphen, { children: !!lowHydration && "".concat(numberRoundOff(lowHydration), "%") })] })] }) })), _jsxs(Grid, { container: true, direction: 'row', justifyContent: 'center', alignItems: 'stretch', mt: 3, mb: 3, gap: '8px', children: [_jsx(Grid, { item: true, width: '240px', children: _jsxs(Box, { className: classes.summaryBox, children: [_jsx(Box, { className: classes.summaryBoxLabel, alignSelf: 'flex-start', children: "TARGET HYDRATION" }), _jsxs(Box, { display: 'flex', gap: '10px', marginBottom: '5px', width: '100%', children: [_jsx(InputText, { type: 'number', value: targetHydration, onChange: function (e) {
                                                                return setTargetHydration(parseFloat(e.target.value));
                                                            }, onBlur: function () {
                                                                if (!targetHydration || targetHydration < 0) {
                                                                    setTargetHydration(0);
                                                                }
                                                                else if (targetHydration > 100) {
                                                                    setTargetHydration(100);
                                                                }
                                                            }, style: { borderRadius: '10px', width: '97px' }, disabled: !isEdit }), _jsx(Button, { variant: 'contained', color: 'primary', className: appClasses.smallButton, style: { minWidth: '120px' }, onClick: isEdit ? handleSetHydrationGoal : function () { return setIsEdit(true); }, "data-testid": 'weight-goal-button', disabled: isLoading || !targetHydration || targetHydration < 1, children: isEdit ? 'Save' : 'Edit' })] })] }) }), _jsx(Grid, { item: true, flexGrow: 1, maxWidth: '128px', children: _jsxs(Box, { className: classes.summaryBox, children: [_jsx(Box, { className: classes.summaryBoxLabel, children: "INCREASE" }), _jsx(Box, { className: clsx('hydration', (_f = {},
                                                        _f[classes.summaryBoxValueData] = !lastRecordedDate,
                                                        _f[classes.lastRecordedColor] = lastRecordedDate,
                                                        _f)), children: _jsx(ShowHyphen, { children: !!increase && "".concat(roundOff(increase), "%") }) })] }) }), _jsx(Grid, { item: true, flexGrow: 1, maxWidth: '128px', children: _jsxs(Box, { className: classes.summaryBox, children: [_jsx(Box, { className: classes.summaryBoxLabel, children: "DECREASE" }), _jsx(Box, { className: clsx('hydration', (_g = {},
                                                        _g[classes.summaryBoxValueData] = !lastRecordedDate,
                                                        _g[classes.lastRecordedColor] = lastRecordedDate,
                                                        _g)), children: _jsx(ShowHyphen, { children: !!decrease && "".concat(roundOff(decrease), "%") }) })] }) })] })] }), _jsxs(Grid, { item: true, xs: 12, sm: 12, md: 8, children: [_jsx(Box, { className: classes.headerContainer, children: _jsx(Grid, { container: true, children: _jsxs(Grid, { item: true, sm: 12, style: { display: 'flex', justifyContent: 'space-between' }, children: [_jsx(Box, { className: globalClasses.cardTitle, ml: 2, children: "Daily Avg" }), _jsx(Box, { className: globalClasses.cardSubTitle, children: !lastRecordedDate ? (_jsxs(Box, { display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', children: [hydrationDiff ? (_jsx(Box, { style: {
                                                                marginRight: 5,
                                                                transform: "rotate(\n                    ".concat(hydrationDiff > 0 ? '180deg' : '0deg', ")"),
                                                            }, children: _jsx(DiffArrow, { height: 25, width: 25, fill: hydrationDiff > 0
                                                                    ? theme.palette.customColor.hydrationGreen
                                                                    : theme.palette.customColor.hydrationRed }) })) : null, roundOff(hydrationDiff)] })) : (_jsxs(Box, { className: globalClasses.lastRecordedTitle, children: ["Last recorded \u00A0", lastRecordedDate] })) })] }) }) }), _jsx(Box, { children: victoryAreaChartData ? (_jsxs(VictoryChart, { width: dailyAvgChartConfig.chart.width, minDomain: {
                                        y: getMinMaxAxis(totalHydration, 'min'),
                                    }, maxDomain: {
                                        y: getMinMaxAxis(totalHydration, 'max'),
                                    }, containerComponent: _jsx(VictoryVoronoiContainer, { voronoiBlacklist: ['interuptionLine'], 
                                        // labels={({datum}) => `${roundOff(datum.y)}`}
                                        labels: function (_a) {
                                            var datum = _a.datum;
                                            if ("".concat(roundOff(datum.y)) === '-') {
                                                return '';
                                            }
                                            return "".concat(roundOff(datum.y));
                                        }, labelComponent: _jsx(VictoryTooltip, { text: tooltipData ? "".concat(tooltipData.y.toFixed(1), "%") : "", cornerRadius: 0, flyoutStyle: {
                                                stroke: 'transparent',
                                                strokeWidth: 0,
                                                fontWeight: 300,
                                                fontSize: 12,
                                                fill: theme.palette.background.green,
                                            }, dy: -7, constrainToVisibleArea: true, style: { fontSize: 20 } }) }), children: [_jsx("defs", { children: _jsxs("linearGradient", { id: 'gradHydration', x1: '1', y1: '1', x2: '1', y2: '0', children: [_jsx("stop", { offset: '0', stopColor: 'white', stopOpacity: '1' }), _jsx("stop", { offset: '1', stopColor: theme.palette.customColor.hydrationBlue, stopOpacity: '1' })] }) }), _jsx(VictoryLabel, { text: dailyAvgChartConfig.label.text, dx: dailyAvgChartConfig.label.dx, dy: dailyAvgChartConfig.label.dy }), _jsx(VictoryAxis, { orientation: dailyAvgChartConfig.yAxis.orientation, dependentAxis: true, style: dailyAvgChartConfig.yAxis.style, label: 'Percentage (%)', axisLabelComponent: _jsx(VictoryLabel, { angle: 0, dy: -130, dx: -37, style: {
                                                    fontWeight: 600,
                                                    fontSize: 15,
                                                } }) }), _jsxs(VictoryGroup, { style: {
                                                data: {
                                                    fillOpacity: 0.7,
                                                    stroke: theme.palette.customColor.percentBlue,
                                                    strokeWidth: 2,
                                                },
                                            }, data: victoryAreaChartData, color: theme.palette.common.white, children: [_jsx(VictoryArea, { style: {
                                                        data: { fill: 'url(#gradHydration)' },
                                                    }, name: 'area' }), _jsx(VictoryScatter, { data: victoryAreaChartData, style: { data: { fill: theme.palette.common.white } }, size: 4, events: [
                                                        {
                                                            target: 'data',
                                                            eventHandlers: {
                                                                onMouseOver: function (event, target) {
                                                                    handleMouseOver(target);
                                                                },
                                                                onMouseOut: function () {
                                                                    handleMouseOut();
                                                                },
                                                            },
                                                        },
                                                    ] })] }), _jsx(VictoryAxis, { fixLabelOverlap: true, orientation: dailyAvgChartConfig.xAxis.orientation }), _jsx(VictoryLine, { style: {
                                                data: {
                                                    stroke: theme.palette.customColor.percentBlue,
                                                    strokeWidth: 2,
                                                    strokeDasharray: '4,4',
                                                },
                                            }, name: 'interuptionLine', data: interupptionLine })] })) : (_jsxs(Box, { display: 'flex', flexDirection: 'column', mt: 12, children: [_jsx(Box, { style: {
                                                textAlign: 'center',
                                                width: '100%',
                                                color: theme.palette.customColor.lighterBlack,
                                                fontSize: 18,
                                            }, children: "No Data" }), _jsx(VictoryChart, { width: dailyAvgChartConfig.chart.width, height: 200, children: _jsx(VictoryAxis, { orientation: dailyAvgChartConfig.xAxis.orientation, fixLabelOverlap: true, tickValues: graphTickValues, style: {
                                                    axis: { stroke: theme.palette.customColor.borderGrey },
                                                    tickLabels: {
                                                        fill: theme.palette.customColor.lighterBlack,
                                                    },
                                                } }) })] })) })] })] }) }) }));
};
export { BodyHydration };
var bodyHydrationStyle = makeStyles()(function (theme) {
    var _a;
    return ({
        circle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 150,
            width: 150,
            borderRadius: '50%',
            padding: 5,
            background: theme.palette.background.green,
        },
        bodyContainerLeft: (_a = {
                borderRight: "1px solid ".concat(theme.palette.customColor.lightSeparator),
                paddingRight: 16
            },
            _a[theme.breakpoints.down('md')] = {
                borderRight: "0px",
                paddingRight: 0,
            },
            _a),
        circleValue: {
            fontSize: 44,
            fontWeight: 'bold',
        },
        squareHigh: {
            borderRadius: 10,
            padding: '0.5rem 1rem',
            border: "solid 2px ".concat(theme.palette.customColor.highGreen),
            color: theme.palette.common.black,
            textAlign: 'center',
            width: 'calc(50% - 0.5rem)',
        },
        noData: {
            border: "solid 2px ".concat(theme.palette.customColor.noDataGrey),
        },
        noDataRecordColor: {
            color: theme.palette.customColor.noDataGrey,
        },
        squareLow: {
            borderRadius: 10,
            padding: '0.5rem 1rem',
            border: "solid 2px ".concat(theme.palette.customColor.lighterBlack),
            color: theme.palette.common.black,
            textAlign: 'center',
            width: 'calc(50% - 0.5rem)',
        },
        squareLabel: {
            color: theme.palette.customColor.lighterBlack,
        },
        graphContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
            margin: 'auto',
            marginTop: '2rem',
            width: 'calc(100% - 80px)',
            paddingTop: '80px',
            position: 'relative',
        },
        hydrationBar: {
            border: "1px solid ".concat(theme.palette.customColor.percentBlue),
            height: '48px',
            borderRadius: '24px',
            overflow: 'hidden',
            width: '100%',
            '& > div': {
                height: '46px',
                backgroundColor: theme.palette.customColor.hydrationBlue,
            },
        },
        tooltip: {
            backgroundColor: theme.palette.background.green,
            padding: '4px 12px',
            boxShadow: '0 6px 12px 0 rgba(0, 0, 0, 0.16)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            '&:after': {
                content: "''",
                position: 'absolute',
                top: '100%',
                left: '50%',
                marginLeft: '-10px',
                borderWidth: '10px',
                borderStyle: 'solid',
                borderColor: "".concat(theme.palette.background.green, " transparent transparent transparent"),
            },
        },
    });
});
