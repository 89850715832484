/* eslint-disable no-unused-vars */
export var VitalState;
(function (VitalState) {
    VitalState["UNASSIGNED"] = "unassigned";
    VitalState["ASSIGNED"] = "assigned";
    VitalState["ACTIVE_INSIGHT"] = "active_insight";
    VitalState["DEACTIVATED"] = "DEACTIVATED";
})(VitalState || (VitalState = {}));
export var EXISTING_VITALS;
(function (EXISTING_VITALS) {
    EXISTING_VITALS["BodyWeight"] = "body_weight";
    EXISTING_VITALS["HeartRateMeasurement"] = "heart_rate_measurement";
    EXISTING_VITALS["BodyHydration"] = "body_hydration";
    EXISTING_VITALS["SleepScore"] = "sleep_score";
    EXISTING_VITALS["SleepDuration"] = "sleep_duration";
    EXISTING_VITALS["SleepDepth"] = "sleep_depth";
    EXISTING_VITALS["ActivityMeasurement"] = "activity_measurement";
    EXISTING_VITALS["ActivityScore"] = "activity_score";
    EXISTING_VITALS["WellnessSurvey"] = "wellness_survey";
})(EXISTING_VITALS || (EXISTING_VITALS = {}));
export var ToastMessageType;
(function (ToastMessageType) {
    ToastMessageType["Success"] = "success";
    ToastMessageType["Error"] = "error";
    ToastMessageType["Info"] = "info";
    ToastMessageType["Warning"] = "warning";
})(ToastMessageType || (ToastMessageType = {}));
export var CareInsightTypes;
(function (CareInsightTypes) {
    CareInsightTypes["Action"] = "action";
    CareInsightTypes["Attention"] = "attention";
    CareInsightTypes["Good_News"] = "good_news";
    CareInsightTypes["Summary"] = "summary";
    CareInsightTypes["Alert"] = "alert";
    CareInsightTypes["Facility_Summary"] = "facility_summary";
})(CareInsightTypes || (CareInsightTypes = {}));
export var WellnessDashboardVitals;
(function (WellnessDashboardVitals) {
    WellnessDashboardVitals["HeartRate"] = "heart_rate";
    WellnessDashboardVitals["Hr_Variablity"] = "hr_variablity";
    WellnessDashboardVitals["Body_Health"] = "body_health";
    WellnessDashboardVitals["Activity"] = "activity";
    WellnessDashboardVitals["Sleep"] = "sleep";
})(WellnessDashboardVitals || (WellnessDashboardVitals = {}));
export var EventsType;
(function (EventsType) {
    EventsType["Summary"] = "summary";
    EventsType["Alert"] = "alert";
    EventsType["SOS"] = "sos";
    EventsType["FALL"] = "fall";
    EventsType["CALL_ENTRY"] = "call_entry";
    EventsType["MILESTONE"] = "milestone";
    EventsType["RESOURCES"] = "resources";
})(EventsType || (EventsType = {}));
export var LocateMePostAPIResponseType;
(function (LocateMePostAPIResponseType) {
    LocateMePostAPIResponseType[LocateMePostAPIResponseType["Success"] = 200] = "Success";
})(LocateMePostAPIResponseType || (LocateMePostAPIResponseType = {}));
export var APIMethod;
(function (APIMethod) {
    APIMethod["Post"] = "post";
    APIMethod["Get"] = "get";
    APIMethod["Put"] = "put";
    APIMethod["Delete"] = "delete";
})(APIMethod || (APIMethod = {}));
export var EventViewState;
(function (EventViewState) {
    EventViewState[EventViewState["Maximize"] = 0] = "Maximize";
    EventViewState[EventViewState["Minimize"] = 1] = "Minimize";
    EventViewState[EventViewState["Approve"] = 2] = "Approve";
    EventViewState[EventViewState["ActionNotification"] = 3] = "ActionNotification";
    EventViewState[EventViewState["Hide"] = 4] = "Hide";
})(EventViewState || (EventViewState = {}));
export var CareInsightStatus;
(function (CareInsightStatus) {
    CareInsightStatus["Approved"] = "approved";
    CareInsightStatus["New"] = "new";
    CareInsightStatus["Sent"] = "sent";
    CareInsightStatus["Abandoned"] = "abandoned";
    CareInsightStatus["NoAction"] = "no_action";
    CareInsightStatus["SentForApproval"] = "sent_for_approval";
    CareInsightStatus["ApprovedWithEdit"] = "approved_with_edit";
    CareInsightStatus["Denied"] = "denied";
})(CareInsightStatus || (CareInsightStatus = {}));
export var PusherConnectionStatus;
(function (PusherConnectionStatus) {
    PusherConnectionStatus["Disconnected"] = "disconnected";
    PusherConnectionStatus["Connected"] = "connected";
})(PusherConnectionStatus || (PusherConnectionStatus = {}));
export var LocationStatus;
(function (LocationStatus) {
    LocationStatus[LocationStatus["AWAY"] = 0] = "AWAY";
    LocationStatus[LocationStatus["HOME"] = 1] = "HOME";
    LocationStatus[LocationStatus["NO_LOCATION"] = 2] = "NO_LOCATION";
})(LocationStatus || (LocationStatus = {}));
export var GoogleMapAddressComponent;
(function (GoogleMapAddressComponent) {
    GoogleMapAddressComponent["streetNumber"] = "street_number";
    GoogleMapAddressComponent["route"] = "route";
    GoogleMapAddressComponent["locality"] = "locality";
    GoogleMapAddressComponent["postalCode"] = "postal_code";
    GoogleMapAddressComponent["state"] = "administrative_area_level_1";
})(GoogleMapAddressComponent || (GoogleMapAddressComponent = {}));
export var autoCompleteEvents;
(function (autoCompleteEvents) {
    autoCompleteEvents["placeChanged"] = "place_changed";
})(autoCompleteEvents || (autoCompleteEvents = {}));
export var Roles;
(function (Roles) {
    // SuperAdmin = 'super_admin',
    Roles["Admin"] = "admin";
    Roles["CareAgent"] = "careagent";
    // Senior = 'senior',
    Roles["WarehouseEmployee"] = "warehouse-employee";
    Roles["BDM"] = "bdm";
    Roles["FACILITY_AGENT"] = "facility-agent";
})(Roles || (Roles = {}));
export var FontSize;
(function (FontSize) {
    FontSize["LARGE"] = "large";
    FontSize["SMALL"] = "small";
})(FontSize || (FontSize = {}));
export var GoalStatus;
(function (GoalStatus) {
    GoalStatus["Started"] = "started";
    GoalStatus["Not_Started"] = "not_started";
    GoalStatus["In_Progress"] = "in_progress";
    GoalStatus["Cancelled"] = "cancelled";
    GoalStatus["Completed"] = "completed";
    GoalStatus["Deleted"] = "deleted";
})(GoalStatus || (GoalStatus = {}));
export var AlarmType;
(function (AlarmType) {
    AlarmType["SOS"] = "sos";
    AlarmType["FALL"] = "fall";
})(AlarmType || (AlarmType = {}));
export var AlarmStatus;
(function (AlarmStatus) {
    AlarmStatus["PENDING"] = "pending";
    AlarmStatus["ASSIGNED"] = "assigned";
    AlarmStatus["COMPLETED"] = "completed";
})(AlarmStatus || (AlarmStatus = {}));
export var AssessmentStatus;
(function (AssessmentStatus) {
    AssessmentStatus["Save"] = "save";
    AssessmentStatus["Submit"] = "submit";
    AssessmentStatus["Reset"] = "reset";
    AssessmentStatus["OK"] = "OK";
    AssessmentStatus["YES"] = "Yes";
    AssessmentStatus["NO"] = "No";
})(AssessmentStatus || (AssessmentStatus = {}));
export var QuestionnaireStatus;
(function (QuestionnaireStatus) {
    QuestionnaireStatus["Save"] = "save";
    QuestionnaireStatus["Submit"] = "submit";
    QuestionnaireStatus["Reset"] = "reset";
    QuestionnaireStatus["OK"] = "OK";
    QuestionnaireStatus["YES"] = "Yes";
    QuestionnaireStatus["NO"] = "No";
})(QuestionnaireStatus || (QuestionnaireStatus = {}));
export var AssessmentName;
(function (AssessmentName) {
    AssessmentName["CAREGIVER_STRAIN"] = "caregiver-strain-assessment";
    AssessmentName["HOLISTIC"] = "holistic-assessment";
    AssessmentName["KATZ_INDEPENDENCE"] = "katz-independence";
    AssessmentName["LAWTON_BRODY"] = "lawton-brody";
    AssessmentName["WELLNESS_SURVEY"] = "wellness-survey";
    AssessmentName["MEDICAL_CONDITION"] = "medical-condition";
    AssessmentName["MEDICATION_LIST"] = "medication-list";
    AssessmentName["UCLA_LONELINESS"] = "ucla_loneliness_assessment";
    AssessmentName["FALLSANDHOSPITALIZATION"] = "fall_hospitalization_assessment";
    AssessmentName["HOMESAFETY"] = "home_safety_assessment";
    AssessmentName["MEMBER_QUESTIONNAIRE_1"] = "member_questionnaire_1";
    AssessmentName["MEMBER_QUESTIONNAIRE_2"] = "member_questionnaire_2";
    AssessmentName["MEMBER_QUESTIONNAIRE_3"] = "member_questionnaire_3";
})(AssessmentName || (AssessmentName = {}));
export var Assessements;
(function (Assessements) {
    Assessements["WELLNESS_SURVEY"] = "Wellness Survey";
    Assessements["HOLISTIC"] = "Holistic assessment";
    Assessements["CAREGIVER_STRAIN"] = "Caregiver Strain assessment";
    Assessements["KATZ_INDEPENDENCE"] = "Katz-Index of Independence assessment";
    Assessements["LAWTON_BRODY"] = "Lawton-Brody assessment";
    Assessements["MEDICAL_CONDITION"] = "Medical Condition/ Disease";
    Assessements["UCLA_LONELINESS"] = "UCLA Loneliness assessment";
    Assessements["FALLSANDHOSPITALIZATION"] = "Falls And Hospitalization assessment";
    Assessements["HOMESAFETY"] = "Home Safety assessment";
    Assessements["MEMBER_QUESTIONNAIRE_1"] = "Member Questionnaire 1";
    Assessements["MEMBER_QUESTIONNAIRE_2"] = "Member Questionnaire 2";
    Assessements["MEMBER_QUESTIONNAIRE_3"] = "Member Questionnaire 3";
})(Assessements || (Assessements = {}));
export var AssessmentButtonAction;
(function (AssessmentButtonAction) {
    AssessmentButtonAction["Save"] = "saved";
    AssessmentButtonAction["Submit"] = "submitted";
    AssessmentButtonAction["Reset"] = "reset";
})(AssessmentButtonAction || (AssessmentButtonAction = {}));
export var HolisticAssessmentAdminStatus;
(function (HolisticAssessmentAdminStatus) {
    HolisticAssessmentAdminStatus["Save"] = "save";
    HolisticAssessmentAdminStatus["Submit"] = "submit";
    HolisticAssessmentAdminStatus["SubmitLater"] = "submit_later";
    HolisticAssessmentAdminStatus["Reset"] = "reset";
})(HolisticAssessmentAdminStatus || (HolisticAssessmentAdminStatus = {}));
export var UCLALonelinessAssessmentAdminStatus;
(function (UCLALonelinessAssessmentAdminStatus) {
    UCLALonelinessAssessmentAdminStatus["Save"] = "save";
    UCLALonelinessAssessmentAdminStatus["Submit"] = "submit";
    UCLALonelinessAssessmentAdminStatus["SubmitLater"] = "submit_later";
    UCLALonelinessAssessmentAdminStatus["Reset"] = "reset";
})(UCLALonelinessAssessmentAdminStatus || (UCLALonelinessAssessmentAdminStatus = {}));
export var FallsAndHospitalizationAssessmentAdminStatus;
(function (FallsAndHospitalizationAssessmentAdminStatus) {
    FallsAndHospitalizationAssessmentAdminStatus["Save"] = "save";
    FallsAndHospitalizationAssessmentAdminStatus["Submit"] = "submit";
    FallsAndHospitalizationAssessmentAdminStatus["SubmitLater"] = "submit_later";
    FallsAndHospitalizationAssessmentAdminStatus["Reset"] = "reset";
})(FallsAndHospitalizationAssessmentAdminStatus || (FallsAndHospitalizationAssessmentAdminStatus = {}));
export var HomeSafetyAssessmentAdminStatus;
(function (HomeSafetyAssessmentAdminStatus) {
    HomeSafetyAssessmentAdminStatus["Save"] = "save";
    HomeSafetyAssessmentAdminStatus["Submit"] = "submit";
    HomeSafetyAssessmentAdminStatus["SubmitLater"] = "submit_later";
    HomeSafetyAssessmentAdminStatus["Reset"] = "reset";
})(HomeSafetyAssessmentAdminStatus || (HomeSafetyAssessmentAdminStatus = {}));
export var CIRangeMilestones;
(function (CIRangeMilestones) {
    CIRangeMilestones["Pending"] = "pending";
    CIRangeMilestones["Completed"] = "completed";
})(CIRangeMilestones || (CIRangeMilestones = {}));
export var ThresholdOperations;
(function (ThresholdOperations) {
    ThresholdOperations["GT"] = "gt";
    ThresholdOperations["LT"] = "lt";
    ThresholdOperations["EQ"] = "eq";
    ThresholdOperations["GTE"] = "gte";
    ThresholdOperations["LTE"] = "lte";
})(ThresholdOperations || (ThresholdOperations = {}));
export var CaregiverType;
(function (CaregiverType) {
    CaregiverType["PRIMARY"] = "primary";
    CaregiverType["SECONDARY"] = "secondary";
    CaregiverType["ALTERNATE"] = "alternate";
})(CaregiverType || (CaregiverType = {}));
export var GetSeniorData;
(function (GetSeniorData) {
    GetSeniorData["SENIOR"] = "senior";
    GetSeniorData["CAREGIVER"] = "caregiver";
    GetSeniorData["PROVIDER"] = "provider";
    GetSeniorData["DEVICES"] = "devices";
})(GetSeniorData || (GetSeniorData = {}));
export var CGAssessmentOptions;
(function (CGAssessmentOptions) {
    CGAssessmentOptions["NO"] = "no";
    CGAssessmentOptions["REGULAR"] = "regular";
    CGAssessmentOptions["SOMETIMES"] = "sometimes";
})(CGAssessmentOptions || (CGAssessmentOptions = {}));
export var OnboardingTab;
(function (OnboardingTab) {
    OnboardingTab["PROFILE_INFO"] = "profile-info";
    OnboardingTab["PROVIDER_INFO"] = "provider-info";
    OnboardingTab["MEDICAL_INFO"] = "medical-info";
    OnboardingTab["CARE_CIRCLE"] = "care-circle";
    OnboardingTab["DEVICES"] = "devices";
    OnboardingTab["SURVEY"] = "survey";
    OnboardingTab["ASSESSMENTS"] = "assessments";
})(OnboardingTab || (OnboardingTab = {}));
export var GraphView;
(function (GraphView) {
    GraphView["DAY"] = "day";
    GraphView["WEEK"] = "week";
    GraphView["MONTH"] = "month";
})(GraphView || (GraphView = {}));
export var ThemeVersion;
(function (ThemeVersion) {
    ThemeVersion[ThemeVersion["v1"] = 0] = "v1";
    ThemeVersion[ThemeVersion["v2"] = 1] = "v2";
})(ThemeVersion || (ThemeVersion = {}));
export var Float;
(function (Float) {
    Float["Right"] = "right";
    Float["Left"] = "left";
})(Float || (Float = {}));
export var ResourceFormats;
(function (ResourceFormats) {
    ResourceFormats["URL"] = "url";
    ResourceFormats["PDF"] = "pdf";
})(ResourceFormats || (ResourceFormats = {}));
export var PrintTemplates;
(function (PrintTemplates) {
    PrintTemplates["HOLISTIC"] = "holistic-assessment";
    PrintTemplates["LAWTON"] = "lawton-brody";
    PrintTemplates["KATZ"] = "katz-independence";
    PrintTemplates["CAREGIVER_STRAIN"] = "caregiver-strain-assessment";
    PrintTemplates["MEDICAL_CONDITION"] = "medical-condition";
    PrintTemplates["UCLA_LONELINESS"] = "UCLALoneliness-assessment";
    PrintTemplates["FALLSANDHOSPITALIZATION"] = "fallsAndHospitalization-assessment";
    PrintTemplates["HOMESAFETY"] = "homeSafety-assessment";
    PrintTemplates["MEMBER_QUESTIONNAIRE_1"] = "member_questionnaire_1";
    PrintTemplates["MEMBER_QUESTIONNAIRE_2"] = "member_questionnaire_2";
    PrintTemplates["MEMBER_QUESTIONNAIRE_3"] = "member_questionnaire_3";
})(PrintTemplates || (PrintTemplates = {}));
export var PreviewTemplates;
(function (PreviewTemplates) {
    PreviewTemplates["MEDICAL_CONDITION"] = "medical-condition";
})(PreviewTemplates || (PreviewTemplates = {}));
export var DeviceType;
(function (DeviceType) {
    DeviceType["WATCH"] = "watch";
    DeviceType["SLEEP"] = "sleep";
    DeviceType["SCALE"] = "scale";
    DeviceType["WITHINGS_HUB"] = "withings_hub";
})(DeviceType || (DeviceType = {}));
export var TableSelectionType;
(function (TableSelectionType) {
    TableSelectionType["SINGLE"] = "single";
    TableSelectionType["MULTIPLE"] = "multiple";
    TableSelectionType["NONE"] = "none";
    TableSelectionType["ROW"] = "row";
})(TableSelectionType || (TableSelectionType = {}));
export var SelectAllStatus;
(function (SelectAllStatus) {
    SelectAllStatus["NONE"] = "none";
    SelectAllStatus["SOME"] = "some";
    SelectAllStatus["ALL"] = "all";
})(SelectAllStatus || (SelectAllStatus = {}));
export var ProviderTypes;
(function (ProviderTypes) {
    ProviderTypes["DOCTOR"] = "doctor";
    ProviderTypes["DENTIST"] = "dentist";
    ProviderTypes["PHARMACY"] = "pharmacy";
})(ProviderTypes || (ProviderTypes = {}));
export var AvatarVariants;
(function (AvatarVariants) {
    AvatarVariants["CIRCULAR"] = "circular";
    AvatarVariants["ROUNDED"] = "rounded";
    AvatarVariants["SQUARE"] = "square";
})(AvatarVariants || (AvatarVariants = {}));
export var ShiftTypes;
(function (ShiftTypes) {
    ShiftTypes["DAY"] = "day";
    ShiftTypes["EVENING"] = "evening";
    ShiftTypes["OVERNIGHT"] = "overnight";
})(ShiftTypes || (ShiftTypes = {}));
export var AssessmentStatuses;
(function (AssessmentStatuses) {
    AssessmentStatuses["COMPLETE"] = "complete";
    AssessmentStatuses["INCOMPLETE"] = "incomplete";
    AssessmentStatuses["DUE"] = "due";
    AssessmentStatuses["NOT_ENABLED"] = "not enabled";
    AssessmentStatuses["ENABLED"] = "enabled";
})(AssessmentStatuses || (AssessmentStatuses = {}));
export var QuestionnaireStatuses;
(function (QuestionnaireStatuses) {
    QuestionnaireStatuses["COMPLETE"] = "complete";
    QuestionnaireStatuses["INCOMPLETE"] = "incomplete";
    QuestionnaireStatuses["DUE"] = "due";
})(QuestionnaireStatuses || (QuestionnaireStatuses = {}));
export var SecretsKeys;
(function (SecretsKeys) {
    SecretsKeys["GOOGLE_MAP_API_KEY"] = "GOOGLE_MAP_API_KEY";
    SecretsKeys["PUSHER_CHANNEL_KEY"] = "PUSHER_CHANNEL_KEY";
    SecretsKeys["PUSHER_CHANNEL_CLUSTER"] = "PUSHER_CHANNEL_CLUSTER";
})(SecretsKeys || (SecretsKeys = {}));
export var PaginationFetchTypes;
(function (PaginationFetchTypes) {
    PaginationFetchTypes["LAST_EVALUATED_KEY"] = "LAST_EVALUATED_KEY";
    PaginationFetchTypes["OFFSET"] = "OFFSET";
})(PaginationFetchTypes || (PaginationFetchTypes = {}));
export var PAGINATION_TYPE;
(function (PAGINATION_TYPE) {
    PAGINATION_TYPE[PAGINATION_TYPE["PRIMARY"] = 0] = "PRIMARY";
    PAGINATION_TYPE[PAGINATION_TYPE["SECONDARY"] = 1] = "SECONDARY";
})(PAGINATION_TYPE || (PAGINATION_TYPE = {}));
export var ZoneType;
(function (ZoneType) {
    ZoneType["WHITE"] = "White";
    ZoneType["BLUE"] = "Blue";
    ZoneType["GREEN"] = "Green";
    ZoneType["GOLD"] = "Gold";
})(ZoneType || (ZoneType = {}));
export var MemberType;
(function (MemberType) {
    MemberType["Home"] = "At Home";
    MemberType["BetaTester"] = "Beta Tester";
    MemberType["AlphaTester"] = "Alpha Tester";
    MemberType["InternalTester"] = "Internal Tester";
    MemberType["Study"] = "Study";
    MemberType["Community"] = "Community";
    MemberType["HomeCareMember"] = "Home Care Member";
})(MemberType || (MemberType = {}));
export var ZoneColor;
(function (ZoneColor) {
    ZoneColor["zoneWhite"] = "zoneWhite";
    ZoneColor["zoneBlue"] = "zoneBlue";
    ZoneColor["zoneGreen"] = "zoneGreen";
    ZoneColor["zoneGold"] = "zoneGold";
})(ZoneColor || (ZoneColor = {}));
export var ACTIVITY_CARD_VARIANT;
(function (ACTIVITY_CARD_VARIANT) {
    ACTIVITY_CARD_VARIANT["MODERATE"] = "MODERATE";
    ACTIVITY_CARD_VARIANT["INTENSE"] = "INTENSE";
    ACTIVITY_CARD_VARIANT["ACTIVITY"] = "ACTIVITY";
    ACTIVITY_CARD_VARIANT["STEPS"] = "STEPS";
})(ACTIVITY_CARD_VARIANT || (ACTIVITY_CARD_VARIANT = {}));
export var PRIMARY_COLOR;
(function (PRIMARY_COLOR) {
    PRIMARY_COLOR["BLUE"] = "BLUE";
    PRIMARY_COLOR["GREEN"] = "GREEN";
})(PRIMARY_COLOR || (PRIMARY_COLOR = {}));
export var DASHBOARD_VITALS_TYPE;
(function (DASHBOARD_VITALS_TYPE) {
    DASHBOARD_VITALS_TYPE["WELLNESS"] = "Wellness";
    DASHBOARD_VITALS_TYPE["ACTIVITY"] = "Activity";
    DASHBOARD_VITALS_TYPE["SLEEP"] = "Sleep";
    DASHBOARD_VITALS_TYPE["HEALTH"] = "Health";
    DASHBOARD_VITALS_TYPE["WELLBEING"] = "Wellbeing";
    DASHBOARD_VITALS_TYPE["HYDRATION"] = "Hydartion";
})(DASHBOARD_VITALS_TYPE || (DASHBOARD_VITALS_TYPE = {}));
export var HEART_RATE_CONDITION;
(function (HEART_RATE_CONDITION) {
    HEART_RATE_CONDITION["NO_DATA"] = "No_Data";
    HEART_RATE_CONDITION["HIGH"] = "High";
    HEART_RATE_CONDITION["GOOD"] = "Good";
    HEART_RATE_CONDITION["LOW"] = "Low";
})(HEART_RATE_CONDITION || (HEART_RATE_CONDITION = {}));
export var ACTIVITY_CONDITION;
(function (ACTIVITY_CONDITION) {
    ACTIVITY_CONDITION["NO_DATA"] = "No_Data";
    ACTIVITY_CONDITION["GOOD"] = "Good";
    ACTIVITY_CONDITION["CONCERN"] = "Concern";
})(ACTIVITY_CONDITION || (ACTIVITY_CONDITION = {}));
export var WELLNESS_CONDITION;
(function (WELLNESS_CONDITION) {
    WELLNESS_CONDITION["NO_DATA"] = "No_Data";
    WELLNESS_CONDITION["CONCERN"] = "Concern";
    WELLNESS_CONDITION["POSITIVE"] = "Positive";
    WELLNESS_CONDITION["GOOD"] = "good";
})(WELLNESS_CONDITION || (WELLNESS_CONDITION = {}));
export var SLEEP_CONDITION;
(function (SLEEP_CONDITION) {
    SLEEP_CONDITION["NO_DATA"] = "No_Data";
    SLEEP_CONDITION["GOOD"] = "Good";
    SLEEP_CONDITION["AVERAGE"] = "Average";
    SLEEP_CONDITION["POOR"] = "Poor";
})(SLEEP_CONDITION || (SLEEP_CONDITION = {}));
export var WEIGHT_CONDITION;
(function (WEIGHT_CONDITION) {
    WEIGHT_CONDITION["NO_DATA"] = "No_Data";
    WEIGHT_CONDITION["NO_CHANGE"] = "No_Change";
    WEIGHT_CONDITION["INCREASE"] = "Increase";
    WEIGHT_CONDITION["DECREASE"] = "Decrease";
})(WEIGHT_CONDITION || (WEIGHT_CONDITION = {}));
export var HYDRATION_CONDITION;
(function (HYDRATION_CONDITION) {
    HYDRATION_CONDITION["NO_DATA"] = "No_Data";
    HYDRATION_CONDITION["NO_CHANGE"] = "No_Change";
    HYDRATION_CONDITION["INCREASE"] = "Increase";
    HYDRATION_CONDITION["DECREASE"] = "Decrease";
})(HYDRATION_CONDITION || (HYDRATION_CONDITION = {}));
export var VITAL_CONDITION_CLASSNAME;
(function (VITAL_CONDITION_CLASSNAME) {
    VITAL_CONDITION_CLASSNAME["GREY"] = "verbiageGrey";
    VITAL_CONDITION_CLASSNAME["RED"] = "verbiageRed";
    VITAL_CONDITION_CLASSNAME["GREEN"] = "verbiageGreen";
    VITAL_CONDITION_CLASSNAME["YELLOW"] = "verbiageYellow";
})(VITAL_CONDITION_CLASSNAME || (VITAL_CONDITION_CLASSNAME = {}));
export var VITAL_CONDITION_CLASSNAMES;
(function (VITAL_CONDITION_CLASSNAMES) {
    VITAL_CONDITION_CLASSNAMES["No_Data"] = "verbiageGrey";
    VITAL_CONDITION_CLASSNAMES["No_Change"] = "verbiageGrey";
    VITAL_CONDITION_CLASSNAMES["High"] = "verbiageRed";
    VITAL_CONDITION_CLASSNAMES["Good"] = "verbiageGreen";
    VITAL_CONDITION_CLASSNAMES["Low"] = "verbiageRed";
    VITAL_CONDITION_CLASSNAMES["Concern"] = "verbiageYellow";
    VITAL_CONDITION_CLASSNAMES["Poor"] = "verbiageRed";
    VITAL_CONDITION_CLASSNAMES["Increase"] = "verbiageRed";
    VITAL_CONDITION_CLASSNAMES["Decrease"] = "verbiageGreen";
    VITAL_CONDITION_CLASSNAMES["Average"] = "verbiageGreen";
    VITAL_CONDITION_CLASSNAMES["Positive"] = "verbiageGreen";
    VITAL_CONDITION_CLASSNAMES["good"] = "verbiageYellow";
})(VITAL_CONDITION_CLASSNAMES || (VITAL_CONDITION_CLASSNAMES = {}));
export var FACILITY_TYPES;
(function (FACILITY_TYPES) {
    FACILITY_TYPES["ASSISTED_LIVING"] = "Assisted Living";
    FACILITY_TYPES["INDEPENDENT_LIVING"] = "Independent Living";
    FACILITY_TYPES["MEMORY_CARE"] = "Home Care";
})(FACILITY_TYPES || (FACILITY_TYPES = {}));
export var Y_N;
(function (Y_N) {
    Y_N["Y"] = "Y";
    Y_N["N"] = "N";
})(Y_N || (Y_N = {}));
export var API_LOAD_STATE;
(function (API_LOAD_STATE) {
    API_LOAD_STATE[API_LOAD_STATE["NOT_LOADED"] = 0] = "NOT_LOADED";
    API_LOAD_STATE[API_LOAD_STATE["PROGRESS"] = 1] = "PROGRESS";
    API_LOAD_STATE[API_LOAD_STATE["SUCCESSFUL"] = 2] = "SUCCESSFUL";
    API_LOAD_STATE[API_LOAD_STATE["ERROR"] = 3] = "ERROR";
})(API_LOAD_STATE || (API_LOAD_STATE = {}));
export var BODY_HEALTH_GOAL_TYPES;
(function (BODY_HEALTH_GOAL_TYPES) {
    BODY_HEALTH_GOAL_TYPES["WEIGHT"] = "weight";
    BODY_HEALTH_GOAL_TYPES["HYDRATION"] = "hydration";
})(BODY_HEALTH_GOAL_TYPES || (BODY_HEALTH_GOAL_TYPES = {}));
export var CHART_ZONES;
(function (CHART_ZONES) {
    CHART_ZONES["OVERALL"] = "overall";
    CHART_ZONES["BLUE"] = "blue";
    CHART_ZONES["GREEN"] = "green";
    CHART_ZONES["GOLD"] = "gold";
})(CHART_ZONES || (CHART_ZONES = {}));
export var RESIDENT_COUNT;
(function (RESIDENT_COUNT) {
    RESIDENT_COUNT["OVERALL"] = "overall";
    RESIDENT_COUNT["BLUE"] = "blue";
    RESIDENT_COUNT["GREEN"] = "green";
    RESIDENT_COUNT["GOLD"] = "gold";
    RESIDENT_COUNT["WHITE"] = "white";
    RESIDENT_COUNT["TOTAL_RESIDENTS"] = "total_residents";
})(RESIDENT_COUNT || (RESIDENT_COUNT = {}));
export var FACILITY_BREADCRUMB_OPTIONS;
(function (FACILITY_BREADCRUMB_OPTIONS) {
    FACILITY_BREADCRUMB_OPTIONS["FACILITY_MANAGEMENT"] = "facility-management";
    FACILITY_BREADCRUMB_OPTIONS["FACILITY_HOME"] = "facilityHomePage";
    FACILITY_BREADCRUMB_OPTIONS["FACILITY_DASHBOARD"] = "facilityDashboard";
    FACILITY_BREADCRUMB_OPTIONS["FACILITY_RESIDENTS"] = "residents";
    FACILITY_BREADCRUMB_OPTIONS["RESIDENT_DASHBOARD"] = "residentDashboard";
    FACILITY_BREADCRUMB_OPTIONS["FACILITY_LOCATION"] = "location";
    FACILITY_BREADCRUMB_OPTIONS["FACILITY_WELLNESS_DATA"] = "wellness-data";
})(FACILITY_BREADCRUMB_OPTIONS || (FACILITY_BREADCRUMB_OPTIONS = {}));
export var API_RESPONSE_FACILITY_TYPE;
(function (API_RESPONSE_FACILITY_TYPE) {
    API_RESPONSE_FACILITY_TYPE["ASSISTED_LIVING"] = "assisted_living";
    API_RESPONSE_FACILITY_TYPE["INDEPENDENT_LIVING"] = "independent_living";
    API_RESPONSE_FACILITY_TYPE["MEMORY_CARE"] = "memory_care";
})(API_RESPONSE_FACILITY_TYPE || (API_RESPONSE_FACILITY_TYPE = {}));
export var API_RESPONSE_REPORT_TYPES;
(function (API_RESPONSE_REPORT_TYPES) {
    API_RESPONSE_REPORT_TYPES["EmergentCare"] = "emergent_care_report";
    API_RESPONSE_REPORT_TYPES["FallsAndSos"] = "falls_and_sos_data_report";
    API_RESPONSE_REPORT_TYPES["BackgroundData"] = "background_data_report";
    API_RESPONSE_REPORT_TYPES["BodyScale"] = "body_scale_report";
    API_RESPONSE_REPORT_TYPES["SleepReport"] = "average_sleep_score_report";
    API_RESPONSE_REPORT_TYPES["AverageBodyHealthMetricsReport"] = "average_body_scale_report";
    API_RESPONSE_REPORT_TYPES["AverageWatchActivityReport"] = "average_watch_activity_report";
    API_RESPONSE_REPORT_TYPES["ContactAuthId"] = "contact_auth_id_report";
    API_RESPONSE_REPORT_TYPES["SeniorPersona"] = "senior_personas_report";
    API_RESPONSE_REPORT_TYPES["WellnessData"] = "vitals_data_report";
    API_RESPONSE_REPORT_TYPES["WellnessPlan"] = "wellness_plan_report";
    API_RESPONSE_REPORT_TYPES["WellbeingSurvey"] = "wellbeing_survey_report";
    API_RESPONSE_REPORT_TYPES["HolisticAssessments"] = "holistic_assessments_report";
    API_RESPONSE_REPORT_TYPES["SOSFall"] = "sos_fall_report";
    API_RESPONSE_REPORT_TYPES["MedicalConditions"] = "medical_conditions_report";
    API_RESPONSE_REPORT_TYPES["MedicationList"] = "medication_list_report";
    API_RESPONSE_REPORT_TYPES["UCLALonelinessAssessment"] = "ucla_loneliness_assessments_report";
    API_RESPONSE_REPORT_TYPES["FallsAndHospitalizationAssessments"] = "fall_hospitalization_assessments_report";
    API_RESPONSE_REPORT_TYPES["HomeSafetyAssessments"] = "home_safety_assessments_report";
    API_RESPONSE_REPORT_TYPES["MemberQuestionnaire1"] = "member_questionnaire_1_report";
    API_RESPONSE_REPORT_TYPES["MemberQuestionnaire2"] = "member_questionnaire_2_report";
    API_RESPONSE_REPORT_TYPES["MemberQuestionnaire3"] = "member_questionnaire_3_report";
    API_RESPONSE_REPORT_TYPES["SleepData"] = "sleep_data_report";
    API_RESPONSE_REPORT_TYPES["WatchActivityReport"] = "watch_activity_report";
    API_RESPONSE_REPORT_TYPES["DisableReport"] = "disable_report";
})(API_RESPONSE_REPORT_TYPES || (API_RESPONSE_REPORT_TYPES = {}));
export var REPORT_STATUS_TYPES;
(function (REPORT_STATUS_TYPES) {
    REPORT_STATUS_TYPES["SUCCESS"] = "success";
    REPORT_STATUS_TYPES["IN_PROGRESS"] = "in_progress";
    REPORT_STATUS_TYPES["FAILED"] = "failed";
})(REPORT_STATUS_TYPES || (REPORT_STATUS_TYPES = {}));
