import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { DATE_FORMAT_SHORT_YEAR, TIME_FORMAT } from 'globals/global.constants';
import { markerTooltipStyle } from './MarkerTooltipComponent.style';
var MarkerTooltipComponent = function (_a) {
    var _b;
    var latitude = _a.latitude, longitude = _a.longitude, address = _a.address, timestamp = _a.timestamp, tooltipIcon = _a.tooltipIcon, label = _a.label, addressPlaceholder = _a.addressPlaceholder, timezone = _a.timezone, locationSource = _a.locationSource;
    var classes = markerTooltipStyle().classes;
    var tooltipData = [
        {
            label: 'Date & time:',
            value: moment(timestamp)
                .tz(timezone)
                .format("".concat(DATE_FORMAT_SHORT_YEAR, " ").concat(TIME_FORMAT)),
        },
        {
            label: 'Address:',
            value: address,
            alternateValue: addressPlaceholder,
        },
        {
            label: 'Latitude:',
            value: latitude,
        },
        {
            label: 'Longitude:',
            value: longitude,
        },
        {
            label: 'Location Source:',
            value: locationSource,
            alternateValue: 'NA',
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Box, { "data-testid": 'markerTooTipComponent', children: label != 'none' ? (_jsx(Box, { className: clsx((_b = {},
                        _b[classes.markerNumber] = true,
                        _b[classes.threeDigitWidth] = label.toString().length == 3,
                        _b)), children: label })) : (_jsx("img", { src: tooltipIcon, width: '28px', height: '28px' })) }), _jsx(Box, { mt: 1, children: tooltipData.map(function (data) {
                    var _a, _b;
                    return (_jsxs(Box, { className: classes.tooltipDetailContainer, children: [_jsx(Box, { width: 100, children: _jsx(Box, { className: classes.tooltipLabel, children: data.label }) }), _jsx(Box, { width: 180, children: _jsx(Box, { className: clsx((_a = {}, _a[classes.tooltipValue] = data.value, _a), (_b = {}, _b[classes.tooltipAlternateValue] = !data.value, _b)), children: data.value || data.alternateValue }) })] }, data.label));
                }) })] }));
};
export default MarkerTooltipComponent;
