import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import React from 'react';
import { Roles } from 'globals/enums';
import { appRoutesEndpoints } from './appRoutesEndpoints';
import ChangePassword from 'pages/WCPages/ChangePassword/ChangePassword';
import { AllResidents, ResidentDashboard, ResidentSubPages, } from 'pages/FacilityPages';
import { facilitySlugs } from 'globals/global.constants';
import { SubHeader } from 'common/SubHeader';
import FacilityHomeScreen from 'pages/FacilityPages/FacilityHomeScreen';
import FacilityDashboard from 'pages/FacilityPages/FacilityDashboard/FacilityDashboard.component';
var Home = React.lazy(function () { return import('pages/WCPages/Home'); });
var SeniorContainer = React.lazy(function () { return import('pages/WCPages/SeniorContainer'); });
var Admin = React.lazy(function () { return import('pages/WCPages/Admin'); });
var AddUserPage = React.lazy(function () { return import('pages/WCPages/AddUser'); });
var WellnessDashboard = React.lazy(function () { return import('pages/WCPages/WellnessDashboard'); });
var AllCallSchedule = React.lazy(function () { return import('pages/WCPages/AllCallSchedule'); });
var LoginPage = React.lazy(function () { return import('pages/WCPages/Login'); });
var ChoosePassword = React.lazy(function () { return import('pages/WCPages/ChoosePassword'); });
var ForgotPassword = React.lazy(function () { return import('pages/WCPages/ForgotPassword'); });
var ForgotPasswordVerification = React.lazy(function () { return import('pages/WCPages/ForgotPassword/ForgotPasswordVerification'); });
/** WC Private routes */
export var privateRoutesList = [
    {
        meta: {
            title: 'Admin',
            description: '',
        },
        path: "".concat(appRoutesEndpoints.admin.baseRoute, "/*"),
        component: _jsx(Admin, {}),
        requiredRoles: [Roles.Admin, Roles.BDM],
    },
    {
        meta: {
            title: 'Home',
            description: '',
        },
        path: appRoutesEndpoints.homePage,
        component: _jsx(Home, {}),
        requiredRoles: [Roles.CareAgent, Roles.WarehouseEmployee, Roles.BDM],
    },
    {
        meta: {
            title: 'AddUser',
            description: '',
        },
        path: '/add-user',
        component: _jsx(AddUserPage, {}),
        requiredRoles: [Roles.CareAgent, Roles.WarehouseEmployee, Roles.BDM, Roles.FACILITY_AGENT],
    },
    {
        meta: {
            title: 'Wellness Dasboard',
            description: '',
        },
        path: '/senior/:seniorID/:accountID/:timezone/wellness-dashboard',
        component: (_jsxs(_Fragment, { children: [_jsx(SubHeader, {}), _jsx(WellnessDashboard, {})] })),
        requiredRoles: [Roles.CareAgent],
    },
    {
        meta: {
            title: 'Call Schedule',
            description: '',
        },
        path: '/call-schedule',
        component: _jsx(AllCallSchedule, {}),
        requiredRoles: [Roles.CareAgent],
    },
    {
        meta: {
            title: 'Senior Dashboard',
            description: '',
        },
        path: '/senior/:seniorID/:accountID/:timezone/:tab/*',
        component: _jsx(SeniorContainer, {}),
        requiredRoles: [Roles.CareAgent, Roles.WarehouseEmployee, Roles.BDM, Roles.FACILITY_AGENT],
    },
    {
        meta: {
            title: 'Change-password',
            description: '',
        },
        path: '/change-password',
        component: _jsx(ChangePassword, {}),
        requiredRoles: [Roles.CareAgent, Roles.WarehouseEmployee, Roles.BDM, Roles.FACILITY_AGENT],
    },
];
/** WC Private routes */
/** Public routes */
export var publicRoutesList = [
    {
        meta: {
            title: 'Login',
            description: '',
        },
        path: '/login',
        component: _jsx(LoginPage, {}),
    },
    {
        meta: {
            title: 'Choose Password',
            description: '',
        },
        path: '/choose-password',
        component: _jsx(ChoosePassword, {}),
    },
    {
        meta: {
            title: 'Forgot Password',
            description: '',
        },
        path: '/forgot-password',
        component: _jsx(ForgotPassword, {}),
    },
    {
        meta: {
            title: 'Forgot Password',
            description: '',
        },
        path: '/forgot-password-verification',
        component: _jsx(ForgotPasswordVerification, {}),
    },
];
/** Public routes */
/** Agency Routes */
var facilityManagement = facilitySlugs.facilityManagement, facilityHomePage = facilitySlugs.facilityHomePage, facilityResident = facilitySlugs.facilityResident, residentDashboard = facilitySlugs.residentDashboard, accountID = facilitySlugs.accountID, timezone = facilitySlugs.timezone, subRoute = facilitySlugs.subRoute;
export var facilityRoutesList = [
    {
        meta: {
            title: 'Agency',
            description: '',
        },
        path: "/".concat(facilityManagement),
        component: _jsx("div", { children: "Agency Management" }),
        requiredRoles: [Roles.Admin, Roles.FACILITY_AGENT, Roles.BDM],
    },
    {
        meta: {
            title: 'Agency Home',
            description: '',
        },
        path: "/".concat(facilityManagement, "/:").concat(facilityHomePage),
        component: _jsx(FacilityHomeScreen, {}),
        requiredRoles: [Roles.Admin, Roles.FACILITY_AGENT],
    },
    {
        meta: {
            title: 'Agency Dashboard',
            description: '',
        },
        path: "/".concat(facilityManagement, "/:").concat(facilityHomePage, "/facilityDashboard"),
        component: _jsx(FacilityDashboard, {}),
        requiredRoles: [Roles.Admin, Roles.FACILITY_AGENT, Roles.BDM],
    },
    {
        meta: {
            title: 'Agency Clients',
            description: '',
        },
        path: "/".concat(facilityManagement, "/:").concat(facilityHomePage, "/:").concat(facilityResident, "?"),
        component: _jsx(AllResidents, {}),
        requiredRoles: [Roles.Admin, Roles.FACILITY_AGENT],
    },
    {
        meta: {
            title: 'Client Dashboard',
            description: '',
        },
        path: "/".concat(facilityManagement, "/:").concat(facilityHomePage, "/:").concat(facilityResident, "?/:").concat(residentDashboard, "/:").concat(accountID, "/:").concat(timezone, "/"),
        component: _jsx(ResidentDashboard, {}),
        requiredRoles: [Roles.Admin, Roles.FACILITY_AGENT],
    },
    {
        meta: {
            description: '',
        },
        path: "/".concat(facilityManagement, "/:").concat(facilityHomePage, "/:").concat(facilityResident, "?/:").concat(residentDashboard, "/:").concat(accountID, "/:").concat(timezone, "?/:").concat(subRoute, "?"),
        component: _jsx(ResidentSubPages, {}),
        requiredRoles: [Roles.Admin, Roles.FACILITY_AGENT],
    },
];
