var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress, Typography, useTheme, Tooltip } from '@mui/material';
import { residentDashboardStyle } from '../../ResidentDashboard.style';
import clsx from 'clsx';
import { emotionalSurveyCardStyle } from './EmotionalSurveyCard.style';
import SemiCircularProgress from 'common/CircularProgress/SemiCircularProgress.component';
import ShowHyphen from 'common/ShowHyphen/ShowHyphen';
import { calulateWellnessScore } from 'pages/WCPages/Assessments/Wellbieng/WellnessSurvey/WellnessSurvey.action';
import React from 'react';
import SurveyTable from 'pages/WCPages/Assessments/Wellbieng/WellnessSurvey/components/SurveyTable';
import useGetWellbeingDashboard from './useGetWellbeingDashboard';
import InfoIcon from '@mui/icons-material/Info';
var EmotionalSurveyCard = function () {
    var theme = useTheme();
    var classes = emotionalSurveyCardStyle().classes;
    var residentDashboardClasses = residentDashboardStyle().classes;
    var _a = useGetWellbeingDashboard(), data = _a.data, isLoading = _a.isLoading;
    var surveyData = React.useMemo(function () {
        if (data) {
            var survey = calulateWellnessScore(data).survey;
            return survey;
        }
        return [];
    }, [data]);
    var scoreLimit = React.useMemo(function () { var _a; return ((_a = surveyData === null || surveyData === void 0 ? void 0 : surveyData[0]) === null || _a === void 0 ? void 0 : _a.scoreLimit) || 7; }, [surveyData]);
    var averageScore = React.useMemo(function () {
        return Math.round(((surveyData === null || surveyData === void 0 ? void 0 : surveyData.reduce(function (a, b) {
            return a + (b === null || b === void 0 ? void 0 : b.score);
        }, 0)) || 0) / ((surveyData === null || surveyData === void 0 ? void 0 : surveyData.length) || 1));
    }, [surveyData]);
    var averagePercent = React.useMemo(function () { return (averageScore / scoreLimit) * 100; }, [averageScore, scoreLimit]);
    var graphColor = React.useMemo(function () {
        if (averageScore >= 0 && averageScore <= 3) {
            return theme.palette.customColor.error;
        }
        else if (averageScore >= 4 && averageScore <= 5) {
            return theme.palette.customColor.amber;
        }
        else {
            return theme.palette.customColor.green;
        }
    }, [averageScore]);
    var tableData = React.useMemo(function () {
        var data = Object.values(surveyData);
        if (data.length) {
            return __spreadArray(__spreadArray([], data, true), Array(5).fill({
                isEmpty: true,
            }), true).slice(0, 5);
        }
        return [];
    }, [surveyData]);
    var tooltipText = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "Bi-Weekly Wellness Assessment" }), _jsx(Typography, { component: 'div', children: "Clients answer 7 questions bi-weekly to evaluate well-being, where higher scores denote better overall wellness. Low scores prompt prompt follow-up calls, involving caregivers or agency support as needed. The wellness portal displays the last five calls with color indicators for quick reference." })] }));
    return (_jsxs(Box, { className: clsx(residentDashboardClasses.card, classes.card), children: [_jsxs(Box, { className: classes.headingContainer, children: [_jsx(Typography, { variant: 'h6v1', color: theme.palette.customColor.black, children: "Emotional Wellbeing Trends" }), _jsx(Tooltip, { title: tooltipText, children: _jsx(Box, { style: { display: 'inline-flex' }, children: _jsx(InfoIcon, { style: { marginLeft: '136px', position: "relative", top: "5px" }, fontSize: 'medium' }) }) })] }), _jsx(Typography, { variant: 'h5', padding: '0 20px', children: "Score Range 1-7" }), _jsx(Box, { className: classes.cicularGraphContainer, children: _jsx(SemiCircularProgress, { value: averagePercent, display: _jsxs(Box, { className: classes.graphInnerValues, children: [_jsx(Box, { className: classes.scoreText, children: _jsx(ShowHyphen, { children: averageScore }) }), _jsx(Typography, { variant: 'body1', children: "Average" })] }), thickness: 4.2, color: graphColor, bgColor: theme.palette.customColor.bgGrey, size: 290, height: 136 }) }), _jsx(Box, { marginTop: '0.25rem', maxHeight: '320px', maxWidth: '100%', overflow: 'auto', children: isLoading ? (_jsx(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', children: _jsx(CircularProgress, {}) })) : (_jsx(SurveyTable, { isResident: true, data: tableData })) })] }));
};
export default EmotionalSurveyCard;
