import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Box, Grid, Typography } from '@mui/material';
import FacilitySummaryTable from './components/FacilitySummaryTable/FacilitySummaryTable.component';
import SOSTable from './components/SOSTable/SOSTable.component';
import { residentDashboardStyle } from './ResidentDashboard.style';
import ResidentDetailSummary from '../AllResidents/component/ResidentDetailSummary/ResidentDetailSummary';
import SeniorDetail from 'pages/WCPages/SeniorDashboard/components/SeniorDetails/SeniorDetail.component';
import { LocationDashboardCard } from 'pages/WCPages/SeniorDashboard/components/WellnessIndicator/LocationDashboardCard.component';
import React from 'react';
import { useAppSelector } from 'hooks/reduxHooks';
import WellnessIndicators from './WellnessIndicators.container';
import { useSOSData } from 'hooks/useSOSData';
var MemoryCareResidentDashboard = function () {
    var classes = residentDashboardStyle().classes;
    var careInsightHistory = useAppSelector(function (state) { return state.seniorDashboard.careInsightHistory.data; });
    var careInsightHistoryLoading = useAppSelector(function (state) { return state.seniorDashboard.careInsightHistory.loading; });
    var summaryData = React.useMemo(function () {
        return careInsightHistory ? careInsightHistory.slice(0, 20) : [];
    }, [careInsightHistory]);
    var _a = useSOSData(), sosFallData = _a.sosFallData, sosLoading = _a.sosLoading, fallLoading = _a.fallLoading;
    return (_jsxs(Box, { display: 'flex', flexDirection: 'column', children: [_jsx(Box, { margin: '20px 0', children: _jsx(Typography, { variant: 'h1v1', className: classes.dashboardTitle, children: "Dashboard" }) }), _jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 12, sm: 12, md: 4, lg: 4, xl: 3, children: [_jsx(SeniorDetail, { isResident: true }), _jsx(ResidentDetailSummary, {}), _jsx(LocationDashboardCard, { isResident: true })] }), _jsxs(Grid, { item: true, xs: 12, sm: 12, md: 8, lg: 8, xl: 9, spacing: 2, children: [_jsx(WellnessIndicators, {}), _jsxs(Grid, { container: true, spacing: 3, children: [_jsx(Grid, { item: true, xs: 12, md: 6, children: _jsxs(Box, { flexGrow: 1, className: classes.card, children: [_jsx(Box, { padding: '12px 20px', children: _jsx(Typography, { variant: 'body2', color: 'primary', children: "Messages" }) }), _jsx(FacilitySummaryTable, { data: summaryData || [], loading: careInsightHistoryLoading })] }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsxs(Box, { width: '100%', className: classes.card, children: [_jsx(Box, { padding: '12px 20px', children: _jsx(Typography, { variant: 'body2', color: 'primary', children: "Fall & SOS Detection" }) }), _jsx(SOSTable, { data: sosFallData || [], loading: sosLoading || fallLoading, height: '485px' })] }) })] })] })] })] }));
};
export default MemoryCareResidentDashboard;
