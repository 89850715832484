var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState, useEffect } from 'react';
import { useAppDispatch } from 'hooks/reduxHooks';
import { Accordion, AccordionDetails, AccordionSummary, Typography, } from '@mui/material';
import moment from 'moment-timezone';
import { getCurrentSenior } from 'globals/global.functions';
import { useLocation } from 'react-router-dom';
import { FacilityThemeProvider } from 'themes';
import { resetWellnessDashboard } from './WellnessDashboard.actions';
import { wellnessDashboardStyle } from './WellnessDashboard.style';
import { RE_RENDER_GRAPH } from './WellnessDashboard.types';
import Activity from './components/Activity';
import BodyHealth from './components/BodyHealth';
import HeartRate from './components/HeartRate';
import Sleep from './components/Sleep';
import { emptySeniorDetail, resetLocationDates, setSeniorDetail, } from 'store/commonReducer/common.action';
var WellnessDashboard = function () {
    var location = useLocation();
    var classes = wellnessDashboardStyle().classes;
    var dispatch = useAppDispatch();
    var _a = useState(''), expanded = _a[0], setExpanded = _a[1];
    if (expanded === 'sleep') {
        var seniorInfo = __assign({}, getCurrentSenior());
        moment.tz.setDefault(seniorInfo.timezone);
    }
    else {
        moment.tz.setDefault(moment.tz.guess());
    }
    useEffect(function () {
        var initialExpanded = getQueryParam('initialExpand') || '';
        setExpanded(initialExpanded);
        return function () {
            moment.tz.setDefault(moment.tz.guess());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var handleChange = function (panel) { return function () {
        if (expanded !== panel) {
            dispatch({ type: RE_RENDER_GRAPH, value: false });
            setExpanded(panel);
        }
        else {
            setExpanded('');
        }
    }; };
    var getQueryParam = function (param) {
        var query = new URLSearchParams(location.search);
        return query.get(param);
    };
    var wellnessComponent = [
        {
            id: 'heart_rate',
            title: 'Heart Rate',
            component: _jsx(HeartRate, {}),
        },
        {
            id: 'body_health',
            title: 'Body Health',
            component: _jsx(BodyHealth, {}),
        },
        {
            id: 'activity',
            title: 'Activity',
            component: _jsx(Activity, {}),
        },
        {
            id: 'sleep',
            title: 'Sleep',
            component: _jsx(Sleep, {}),
        },
    ];
    useEffect(function () {
        dispatch(setSeniorDetail());
        return function () {
            dispatch(emptySeniorDetail());
            dispatch(resetLocationDates());
            dispatch(resetWellnessDashboard());
        };
    }, []);
    return (_jsx(FacilityThemeProvider, { children: wellnessComponent.map(function (data) { return (_jsxs(Accordion, { className: classes.accordianRoot, expanded: expanded === data.id, onChange: handleChange(data.id), children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "panel1a-content-".concat(data.id), id: "panel-header-".concat(data.id), children: _jsx(Typography, { className: classes.title, children: data.title }) }), _jsx(AccordionDetails, { children: expanded === data.id && data.component })] }, data.title)); }) }));
};
export default WellnessDashboard;
