import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Box, TableContainer } from '@mui/material';
import { ResidentTableStyle } from './table.style';
import Table from 'common/Table/Table.component';
import { constructNameInitials, getQueryParamTimezone, } from 'globals/global.functions';
import get from 'lodash.get';
import AvatarComponent from 'common/Avatar/Avatar.component';
import { PRIMARY_COLOR, TableSelectionType } from 'globals/enums';
import { useNavigate } from 'react-router-dom';
import ShowHyphen from 'common/ShowHyphen/ShowHyphen';
import { getDateDifference } from 'globals/date.functions';
import moment from 'moment';
var ResidentTable = function (_a) {
    var residentData = _a.data, isDataLoading = _a.isDataLoading, isFilterLoading = _a.isFilterLoading, _b = _a.isHighlighted, isHighlighted = _b === void 0 ? function (record) { return false; } : _b, _c = _a.onRowClick, onRowClick = _c === void 0 ? function (rowData) { } : _c;
    var classes = ResidentTableStyle().classes;
    var headerData = React.useMemo(function () { return [
        {
            columnId: '',
            label: '',
            style: {
                paddingLeft: '40px',
            },
        },
        {
            columnId: 'lastName',
            label: 'Last Name',
        },
        {
            columnId: 'firstName',
            label: 'First Name',
        },
        { columnId: 'LOS', label: 'LOS' },
        // {columnId: 'zoneType', label: 'Zone'},
        {
            style: {
                paddingLeft: '2.5rem',
            },
            columnId: 'WellnessCoach',
            label: 'Wellness Coach',
        },
    ]; }, []);
    var navigate = useNavigate();
    var rowData = React.useMemo(function () { return ({
        onRowClick: function (rowData) {
            onRowClick(rowData);
            navigate("".concat(rowData.senior_id, "/").concat(rowData.account_id, "/").concat(getQueryParamTimezone(rowData.minimal.location.timezone)));
        },
        values: [
            {
                dataId: 'minimal',
                dataKey: 'profilePicture',
                className: classes.tableCellStyle,
                style: {
                    paddingLeft: '40px',
                    width: '150px',
                },
                render: function (data, value) {
                    return (_jsx(Box, { style: {
                            alignItems: 'center',
                            display: 'flex',
                        }, children: _jsx(AvatarComponent, { altText: 'senior-profile-image', imageUrl: value.profile_image, child: constructNameInitials(get(data, 'name.first_name'), get(data, 'name.last_name')), className: classes.avatarStyle }) }));
                },
            },
            {
                dataId: 'minimal',
                dataKey: 'lastName',
                className: classes.tableCellStyle,
                style: {
                    width: '200px',
                },
                format: function (data) { return get(data, 'name.last_name'); },
            },
            {
                dataId: 'minimal',
                dataKey: 'firstName',
                className: classes.tableCellStyle,
                style: {
                    width: '300px',
                },
                format: function (data) { return get(data, 'name.first_name'); },
            },
            {
                dataId: 'minimal',
                dataKey: 'LOS',
                style: {
                    width: '300px',
                },
                className: classes.tableCellStyle,
                format: function (data) {
                    return String(getDateDifference(moment(), get(data, 'member_since')));
                },
            },
            // {
            //   dataId: 'minimal',
            //   dataKey: 'zone',
            //   style: {
            //     width: '300px',
            //   },
            //   render: (data) => {
            //     const zone = capitalizeFirstLetter(
            //       get(data, 'zone', 'white'),
            //     ) as ZoneType;
            //     return (
            //       <Box className='zone' display='flex' alignItems='center'>
            //         <ZoneChip zoneType={zone} />
            //       </Box>
            //     );
            //   },
            //   className: classes.tableCellStyle,
            // },
            {
                style: {
                    width: '300px',
                    paddingLeft: '2.5rem',
                    minWidth: '250px'
                },
                dataKey: 'coach_name',
                dataId: 'minimal',
                className: classes.tableCellStyle,
                render: function (data) { return _jsx(ShowHyphen, { children: get(data, 'coach_name') }); },
            },
        ],
    }); }, [classes.avatarStyle, classes.tableCellStyle, navigate, onRowClick]);
    return (_jsx(TableContainer, { style: { minHeight: '500px' }, children: _jsx(Table, { isDataLoading: isDataLoading, isFilterLoading: isFilterLoading, isSelectable: function () { return true; }, selectionType: TableSelectionType.ROW, headerData: headerData, rowId: 'userId', rowData: rowData, data: residentData, primaryColor: PRIMARY_COLOR.GREEN, isHighlighted: isHighlighted }) }));
};
export default ResidentTable;
