import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Typography, useTheme, Tooltip } from '@mui/material';
import { goalPercentageStyle } from './GoalPercentage.style';
import { residentDashboardStyle } from '../../ResidentDashboard.style';
import clsx from 'clsx';
import SemiCircularProgress from 'common/CircularProgress/SemiCircularProgress.component';
import ShowHyphen from 'common/ShowHyphen/ShowHyphen';
import GoalPercentageTable from './GoalPercentageTable';
import { roundOff } from 'globals/global.functions';
import useGetGoalsData from './useGetGoalsData';
import InfoIcon from '@mui/icons-material/Info';
var GoalPercentage = function () {
    var _a;
    var classes = goalPercentageStyle().classes;
    var residentDashboardClasses = residentDashboardStyle().classes;
    var theme = useTheme();
    //get goals Data query
    var _b = useGetGoalsData(), data = _b.data, isLoading = _b.isLoading;
    //5 active goal percentage data
    var goalsPercentageData = React.useMemo(function () {
        if (data) {
            var fiveActiveGoals = data.data;
            var sumOfPercentage = fiveActiveGoals.reduce(function (acc, next) { return acc + next.progress; }, 0);
            var average_1 = roundOff(sumOfPercentage / fiveActiveGoals.length, 1);
            var graphColor = function () {
                if (average_1 > 0 && average_1 <= 50) {
                    return theme.palette.customColor.error;
                }
                else if (average_1 > 50 && average_1 <= 80) {
                    return theme.palette.customColor.amber;
                }
                else {
                    return theme.palette.customColor.green;
                }
            };
            return {
                average: isNaN(average_1) ? 0 : average_1,
                fiveActiveGoals: fiveActiveGoals,
                graphColor: graphColor,
            };
        }
        return {
            average: 0,
            fiveActiveGoals: [],
            graphColor: function () { return ''; },
        };
    }, [data]);
    var tooltipText = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "SMART Wellness Goal Setting" }), _jsx(Typography, { component: 'div', children: "Client wellness goals are set using the SMART framework\u2014specific, measurable, attainable, relevant, and time-bound. Through collaboration with wellness coaches, goals are established and progress tracked via a percentage completion system. This monitoring aids in adjusting strategies, ensuring effective goal achievement, and fostering overall wellness." })] }));
    return (_jsxs(Box, { className: clsx((_a = {},
            _a[residentDashboardClasses.card] = true,
            _a[classes.cardContainer] = true,
            _a)), children: [_jsxs(Box, { className: classes.headingContainer, style: { display: 'flex' }, children: [_jsx(Typography, { variant: 'h6v1', color: theme.palette.customColor.black, children: "Goal Percentage" }), _jsx(Tooltip, { title: tooltipText, children: _jsx(Box, { children: _jsx(InfoIcon, { style: { marginLeft: '164px', position: "relative", top: "5px" }, fontSize: 'medium' }) }) })] }), _jsx(Box, { className: classes.cicularGraphContainer, children: _jsx(SemiCircularProgress, { value: goalsPercentageData.average, display: _jsxs(Box, { className: classes.graphInnerValues, children: [_jsx(Box, { className: classes.goalPercentageText, children: _jsxs(ShowHyphen, { children: [goalsPercentageData.average, "%"] }) }), _jsx(Typography, { variant: 'body1', children: "Current" })] }), thickness: 4.2, color: goalsPercentageData.graphColor(), bgColor: theme.palette.customColor.bgGrey, size: 290, height: 136 }) }), _jsx(GoalPercentageTable, { data: goalsPercentageData.fiveActiveGoals, loading: isLoading }), !isLoading && goalsPercentageData.fiveActiveGoals.length > 0 && (_jsxs(Box, { className: classes.bottomOverallGoal, children: [_jsx(Typography, { variant: 'subtitle2', children: "Total Overall Goal Completion" }), _jsxs(Typography, { variant: 'subtitle2', width: '72px', children: [goalsPercentageData.average, "%"] })] }))] }));
};
export default GoalPercentage;
