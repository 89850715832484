import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { facilityTypeSelectStyle } from './FacilityTypeSelect.style';
import clsx from 'clsx';
/**
 * @description component to display senior/resident zone
 * @function FacilityTypeSelect
 */
var FacilityTypeSelect = function (_a) {
    var _b = _a.facilityTypeMenu, facilityTypeMenu = _b === void 0 ? [] : _b, _c = _a.selectedType, selectedType = _c === void 0 ? '' : _c, _d = _a.onChange, onChange = _d === void 0 ? function (e) { } : _d, _e = _a.hasAllOption, hasAllOption = _e === void 0 ? false : _e, disabled = _a.disabled, _f = _a.rootClass, rootClass = _f === void 0 ? '' : _f, _g = _a.disabledOptions, disabledOptions = _g === void 0 ? [] : _g;
    var classes = facilityTypeSelectStyle({ hasAllOption: hasAllOption }).classes;
    return (_jsxs(Select, { disabled: disabled, displayEmpty: true, value: selectedType, onChange: onChange, className: clsx(classes.root, rootClass), inputProps: {
            'data-testid': 'facilitytype-select',
        }, onClick: function (e) {
            e.stopPropagation();
        }, children: [hasAllOption ? (_jsx(MenuItem, { "data-testid": 'allResidents', value: '', children: "All Clients" })) : (_jsx(MenuItem, { "data-testid": 'selectFaiclityType', disabled: true, value: '', style: { display: 'none' }, children: "Select type" })), facilityTypeMenu.map(function (type) { return (_jsx(MenuItem, { "data-testid": "".concat(type, "Type"), value: type, disabled: disabledOptions.includes(type), children: type }, type)); })] }));
};
export default FacilityTypeSelect;
