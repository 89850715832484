import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import facilityDetailHeader from './FacilityDetailHeader.style';
import { maskPhoneNumber } from 'globals/global.functions';
import ShowHyphen from 'common/ShowHyphen/ShowHyphen';
import { useAppSelector } from 'hooks/reduxHooks';
import StateCityFormatter from 'common/StateCityFormatter/StateCityFormatter';
import { useNavigate, useParams } from 'react-router-dom';
import { facilitySlugs } from 'globals/global.constants';
import { appRoutesEndpoints } from 'routes/appRoutesEndpoints';
import useGetFacilityResidentCount from 'pages/FacilityPages/AllResidents/useGetFacilityResidentCount';
import RoleBaseRender from 'common/RoleBaseRender/RoleBaseRender';
import { Roles } from 'globals/enums';
var FacilityDetailHeader = function (_a) {
    var isFacilityButton = _a.isFacilityButton, isResidentButton = _a.isResidentButton;
    var classes = facilityDetailHeader().classes;
    var selectedFacility = useAppSelector(function (state) { var _a, _b; return (_b = (_a = state.corporateAndFacilities) === null || _a === void 0 ? void 0 : _a.facilityManagementData) === null || _b === void 0 ? void 0 : _b.selectedFacility; });
    var navigate = useNavigate();
    var params = useParams();
    var residentData = useGetFacilityResidentCount('headerResidentCount').data;
    var navigateToFacilityDashboard = React.useCallback(function () {
        navigate("".concat(appRoutesEndpoints.facilityAgent.baseRoute, "/").concat(params[facilitySlugs.facilityHomePage], "/").concat(facilitySlugs.facilityDashboard));
    }, [navigate, params]);
    var navigateToFacilityResidents = React.useCallback(function () {
        navigate("".concat(appRoutesEndpoints.facilityAgent.baseRoute, "/").concat(params[facilitySlugs.facilityHomePage], "/residents"));
    }, [navigate, params]);
    var data = useMemo(function () { return [
        { label: 'Agency', value: selectedFacility.name },
        {
            label: 'Location',
            value: (_jsx(StateCityFormatter, { city: selectedFacility.addressCity, state: selectedFacility.addressState })),
        },
        { label: 'Phone #', value: maskPhoneNumber(selectedFacility.phone) },
        // {label: 'Total Residents', value: selectedFacility.totalResidents},
        { label: 'Total Clients', value: residentData === null || residentData === void 0 ? void 0 : residentData.total_residents },
    ]; }, [selectedFacility, residentData === null || residentData === void 0 ? void 0 : residentData.total_residents]);
    var HeaderItems = useCallback(function (_a) {
        var label = _a.label, value = _a.value;
        return (_jsxs(Box, { className: classes.headList, children: [_jsx(Typography, { component: 'span', variant: 'body1Bold', children: label }), _jsxs(Typography, { component: 'span', variant: 'subtitle1', children: [": ", _jsx(ShowHyphen, { children: value })] })] }));
    }, []);
    return (_jsx(Box, { className: classes.container, children: _jsxs(Box, { className: classes.InnerContainer, children: [_jsx(Box, { className: classes.gList, children: data.map(function (item) {
                        return (_jsx(HeaderItems, { label: item.label, value: item.value }, item.label));
                    }) }), isResidentButton && (_jsx(RoleBaseRender, { notForRole: Roles.BDM, children: _jsx(Button, { onClick: navigateToFacilityResidents, color: 'primary', variant: 'contained', className: classes.button, children: "Clients" }) }))] }) }));
};
export default FacilityDetailHeader;
