var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { showApplicationLoader, hideApplicationLoader, } from 'common/ApplicationLoader';
import { Roles, ShiftTypes } from 'globals/enums';
import { getCareAgentService, getCareAgentServiceAdmin } from 'services/careAgentAccountService/careAgentAccount.service';
import { showError } from 'store/commonReducer/common.action';
export var GET_CARE_AGENT_LIST = 'GET_CARE_AGENT_LIST';
export var GET_CARE_AGENT_LIST_SUCCESS = 'GET_CARE_AGENT_LIST_SUCCESS';
export var GET_CARE_AGENT_LIST_FAIL = 'GET_CARE_AGENT_LIST_FAIL';
export var UPDATE_CARE_AGENT_LIST_PAGE_NUMBER = 'UPDATE_CARE_AGENT_LIST_PAGE_NUMBER';
export var RESET_CARE_AGENT_LIST = 'RESET_CARE_AGENT_LIST';
export var GET_CARE_AGENT_SEARCH_LIST_SUCCESS = 'GET_CARE_AGENT_SEARCH_LIST_SUCCESS';
export var GET_COMMUNITY_AGENT_SEARCH_LIST = 'GET_COMMUNITY_AGENT_SEARCH_LIST';
export var SET_CARE_AGENT_LIST_PARAMS = 'SET_CARE_AGENT_LIST_PARAMS';
/**
 * @function getCareAgentList
 * @description action creator to get CareAgent List
 * @param {string} searchQuery
 * @param {any} lastEvaluatedKey
 * @param {boolean} showLoader if set true will show the ApplicationLoader
 * @param {ShiftTypes[]} shift an array of shifts to filter
 * @param {Roles} role role to filter
 */
export var getCareAgentList = function (searchQuery, lastEvaluatedKey, showLoader, shift, role, checkFilterMethod) {
    if (searchQuery === void 0) { searchQuery = ''; }
    if (lastEvaluatedKey === void 0) { lastEvaluatedKey = ''; }
    if (showLoader === void 0) { showLoader = false; }
    if (shift === void 0) { shift = []; }
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var params, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (searchQuery !== '' && !(searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.trim())) {
                        return [2 /*return*/, { data: [], lastEvaluatedKey: '' }];
                    }
                    if (showLoader) {
                        dispatch(showApplicationLoader());
                    }
                    dispatch({ type: GET_CARE_AGENT_LIST });
                    params = {};
                    // if (lastEvaluatedKey) {
                    //   params.last_evaluated_key = lastEvaluatedKey;
                    // }
                    if (searchQuery) {
                        if (checkFilterMethod == "member_id") {
                            params.search_query = JSON.stringify([
                                { type: 'member_id', value: searchQuery },
                            ]);
                        }
                        else {
                            params.search_query = JSON.stringify([
                                { type: 'name', value: searchQuery },
                            ]);
                        }
                    }
                    if (shift.length) {
                        params.shift = JSON.stringify(shift);
                    }
                    if (role) {
                        params.role = role;
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getCareAgentService(params)];
                case 2:
                    response = _a.sent();
                    dispatch(hideApplicationLoader());
                    return [2 /*return*/, {
                            data: response === null || response === void 0 ? void 0 : response.result,
                            lastEvaluatedKey: response === null || response === void 0 ? void 0 : response.lastEvaluatedKey,
                        }];
                case 3:
                    error_1 = _a.sent();
                    dispatch(hideApplicationLoader());
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
/**
 * @function getCareAgentListAdmin
 * @description action creator to get CareAgent List
 * @param {string} searchQuery
 * @param {any} lastEvaluatedKey
 * @param {boolean} showLoader if set true will show the ApplicationLoader
 * @param {ShiftTypes[]} shift an array of shifts to filter
 * @param {Roles} role role to filter
 */
export var getCareAgentListAdmin = function (searchQuery, lastEvaluatedKey, showLoader, shift, role, checkFilterMethod) {
    if (searchQuery === void 0) { searchQuery = ''; }
    if (lastEvaluatedKey === void 0) { lastEvaluatedKey = ''; }
    if (showLoader === void 0) { showLoader = false; }
    if (shift === void 0) { shift = []; }
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (searchQuery !== '' && !(searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.trim())) {
                        return [2 /*return*/, { data: [], lastEvaluatedKey: '' }];
                    }
                    if (showLoader) {
                        dispatch(showApplicationLoader());
                    }
                    dispatch({ type: GET_CARE_AGENT_LIST });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getCareAgentServiceAdmin(searchQuery)];
                case 2:
                    response = _a.sent();
                    dispatch(hideApplicationLoader());
                    return [2 /*return*/, {
                            data: response === null || response === void 0 ? void 0 : response.result,
                            lastEvaluatedKey: response === null || response === void 0 ? void 0 : response.lastEvaluatedKey,
                        }];
                case 3:
                    error_2 = _a.sent();
                    dispatch(hideApplicationLoader());
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
/**
 * @function getCareAgentListWithoutOvernight
 * @description action creator to get CareAgent List without shift Overnight
 * @param {string} searchQuery
 * @param {any} lastEvaluatedKey
 * @param {boolean} showLoader if set true will show the ApplicationLoader
 */
export var getCareAgentListWithoutOvernight = function (searchQuery, lastEvaluatedKey, showLoader, checkFilterMethod) {
    if (searchQuery === void 0) { searchQuery = ''; }
    if (lastEvaluatedKey === void 0) { lastEvaluatedKey = ''; }
    if (showLoader === void 0) { showLoader = false; }
    if (checkFilterMethod === void 0) { checkFilterMethod = ''; }
    return getCareAgentList(searchQuery, lastEvaluatedKey, showLoader, [ShiftTypes.DAY, ShiftTypes.EVENING], Roles.CareAgent, checkFilterMethod);
};
/**
 * @function getCareAgentListSuccess
 * @description action creator to store careAgents table data
 * @param tableData
 */
export var getCareAgentListSuccess = function (tableData) { return function (dispatch) {
    var data = tableData.data, lastEvaluatedKey = tableData.lastEvaluatedKey, searchLastEvaluatedKey = tableData.searchLastEvaluatedKey;
    dispatch({
        type: GET_CARE_AGENT_LIST_SUCCESS,
        payload: {
            data: data,
            lastEvaluatedKey: lastEvaluatedKey,
            searchLastEvaluatedKey: searchLastEvaluatedKey,
        },
    });
}; };
/**
 * @function getCareAgentSearchListSuccess
 * @description action creator to store careAgents table data
 * @param tableData
 */
export var getCareAgentSearchListSuccess = function (tableData) { return function (dispatch) {
    var data = tableData.data, lastEvaluatedKey = tableData.lastEvaluatedKey;
    dispatch({
        type: GET_CARE_AGENT_SEARCH_LIST_SUCCESS,
        payload: {
            data: data,
            searchLastEvaluatedKey: lastEvaluatedKey,
        },
    });
}; };
/**
 * @function getCareAgentListFail
 * @description action creator to fetch error on api get fail
 * @param error
 */
export var getCareAgentListFail = function (error) { return function (dispatch) {
    dispatch(showError(error));
    dispatch({
        type: GET_CARE_AGENT_LIST_FAIL,
    });
}; };
/**
 * @function updateCareAgentListPageNumber
 * @description action creator to update the page number of careAgents table
 * @param {string | number} value
 */
export var updateCareAgentListPageNumber = function (value) { return function (dispatch) {
    dispatch({ type: UPDATE_CARE_AGENT_LIST_PAGE_NUMBER, payload: value });
}; };
/**
 * @function resetCareAgentList
 * @description action creator to reset careAgents table
 */
export var resetCareAgentList = function () { return function (dispatch) {
    dispatch({
        type: RESET_CARE_AGENT_LIST,
    });
}; };
/**
 * @description search careAgent by name
 * @function searchCareAgentByName
 * @param {any[]} data
 * @param {string} searchQuery
 * @returns {any[]}
 */
export var searchCareAgentByName = function (data, searchQuery) {
    if (data === void 0) { data = []; }
    if (searchQuery === void 0) { searchQuery = ''; }
    return data.filter(function (careAgent) {
        var _a;
        var basicInfo = careAgent.name;
        var query = searchQuery.toLowerCase().split(' ');
        var firstName = basicInfo.firstName.toLowerCase() || '';
        var middleName = ((_a = basicInfo.middleName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || '';
        var lastName = basicInfo.lastName.toLowerCase() || '';
        return query.every(function (data) {
            return (firstName.includes(data) ||
                middleName.includes(data) ||
                lastName.includes(data));
        });
    });
};
