var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { VictoryAxis, VictoryArea, VictoryLegend, VictoryChart, VictoryStack, VictoryBar, VictoryLine, VictoryScatter, VictoryGroup, VictoryVoronoiContainer, VictoryLabel, } from 'victory';
import get from 'lodash.get';
import { HeartRespirationTooltip } from 'common/Tooltips';
import { roundToTen } from 'globals/global.functions';
import { heartRateStyle } from './HearRate.style';
var HeartRateActivity = function (_a) {
    var summary = _a.summary, heartRateActivity = _a.heartRateActivity, activityChartData = _a.activityChartData, minMaxData = _a.minMaxData, type = _a.type;
    var theme = useTheme();
    var classes = heartRateStyle().classes;
    var stackChartData = activityChartData;
    var lineChartData = heartRateActivity;
    var areaChartData = minMaxData;
    var legendChartData = [
        {
            name: 'Steps',
            symbol: { fill: theme.palette.customColor.moderateGreen, type: 'square' },
        },
        // {
        //   name: 'Intense',
        //   symbol: {fill: theme.palette.customColor.intenseGreen, type: 'square'},
        // },
        {
            name: 'Heart Rate',
            symbol: { fill: theme.palette.customColor.error, type: 'minus' },
        },
    ];
    // const minValue =
    //   type == 'day' ? roundToTen(get(summary, 'data.min') * 0.8, 'min') : 0;
    var maxValue = roundToTen(get(summary, 'data.max'), 'max');
    var maxSteps = Math.max.apply(Math, stackChartData[0].map(function (data) { return data.y; }));
    var scalingFactor = useMemo(function () {
        switch (true) {
            case maxSteps > 15000:
                return 150;
            case maxSteps > 10000:
                return 100;
            case maxSteps > 5000:
                return 50;
            case maxSteps > 2500:
                return 25;
            case maxSteps > 1000:
                return 10;
            case maxSteps > 500:
                return 5;
            default:
                return 1;
        }
    }, [maxSteps]);
    var maxValues = Math.max.apply(Math, stackChartData[0].map(function (data) { return data.y / scalingFactor; }));
    var maxValGraph = Math.max(maxValue, maxValues);
    var getArray = function (min, max) {
        var step = 10;
        var len = Math.floor((max - min) / step) + 1;
        return Array(len)
            .fill(0)
            .map(function (_, idx) { return min + idx * step; });
    };
    var _b = useState(null), activeBar = _b[0], setActiveBar = _b[1];
    return (_jsx(Box, { className: classes.activityContainer, "data-testid": 'heart-rate-activity', children: _jsxs(VictoryChart, { minDomain: { y: 0 }, maxDomain: { y: maxValGraph }, domainPadding: 50, width: 900, height: 300, containerComponent: _jsx(VictoryVoronoiContainer, { voronoiBlacklist: ['area'], activateData: true, labels: function (_a) {
                    var datum = _a.datum;
                    return (datum ? "".concat(datum.x) : '');
                }, labelComponent: _jsx(HeartRespirationTooltip, { data2: areaChartData, data: lineChartData, stackChartData: stackChartData, activeBar: activeBar }) }), children: [_jsx(VictoryLegend, { orientation: 'horizontal', gutter: 20, symbolSpacer: 6, x: 320, y: 10, style: {
                        labels: { fontSize: 16, padding: 0, margin: 0 },
                    }, data: legendChartData }), _jsx(VictoryAxis, { orientation: 'left', dependentAxis: true, tickFormat: function (t) { return "".concat(t * scalingFactor); }, fixLabelOverlap: true, style: {
                        axis: {
                            stroke: theme.palette.customColor.lighterBlack,
                        },
                        tickLabels: {
                            fill: theme.palette.customColor.lighterBlack,
                            fontSize: 15,
                        },
                    }, label: 'Steps', axisLabelComponent: _jsx(VictoryLabel, { angle: 0, dy: -130, style: {
                            fontWeight: 600,
                            fontSize: 15,
                            color: theme.palette.customColor.lighterBlack,
                        } }) }), _jsx(VictoryAxis, { orientation: 'bottom', style: {
                        axis: {
                            stroke: theme.palette.customColor.titleBlack,
                        },
                        tickLabels: {
                            fill: theme.palette.customColor.titleBlack,
                            fontSize: 15,
                        },
                    }, tickFormat: function (x) { return x; }, fixLabelOverlap: true }), _jsx(VictoryAxis, { orientation: 'right', dependentAxis: true, 
                    // tickFormat={getArray(0, maxValue)}
                    fixLabelOverlap: true, style: {
                        axis: {
                            stroke: theme.palette.customColor.lighterBlack,
                        },
                        tickLabels: {
                            fill: theme.palette.customColor.lighterBlack,
                            fontSize: 15,
                        },
                    }, label: 'bpm', axisLabelComponent: _jsx(VictoryLabel, { angle: 0, dy: -130, style: {
                            fontWeight: 600,
                            fontSize: 15,
                            color: theme.palette.customColor.lighterBlack,
                        } }) }), _jsx(VictoryArea, { name: 'area', interpolation: 'natural', style: {
                        data: { fill: theme.palette.customColor.chartGrey },
                    }, data: areaChartData }), _jsx(VictoryStack, { colorScale: [
                        theme.palette.customColor.moderateGreen,
                        theme.palette.customColor.intenseGreen,
                    ], children: stackChartData.map(function (chartData) {
                        chartData = chartData.map(function (data) { return (__assign(__assign({}, data), { y: data.y / scalingFactor })); });
                        return (_jsx(VictoryBar, { name: 'bar', style: { data: chartData.length > 2 ? {} : { width: 30 } }, data: chartData, events: [{
                                    target: "data",
                                    eventHandlers: {
                                        onMouseOver: function (evt, props) {
                                            setActiveBar(props.datum); // Set the hovered datum
                                        },
                                        onMouseOut: function () {
                                            setActiveBar(null); // Reset the hovered datum
                                        },
                                    },
                                }] }, "heartRateActivity-".concat(chartData.length)));
                    }) }), _jsxs(VictoryGroup, { style: {
                        data: {
                            fillOpacity: 0.7,
                            stroke: theme.palette.customColor.intenseGreen,
                            strokeWidth: 2,
                        },
                    }, color: theme.palette.common.white, data: lineChartData, children: [_jsx(VictoryLine, { style: { data: { stroke: theme.palette.customColor.error } } }), _jsx(VictoryScatter, { style: { data: { fill: theme.palette.common.white } }, size: 4, events: [{
                                    target: "data",
                                    eventHandlers: {
                                        onMouseOver: function (evt, props) {
                                            setActiveBar(props.datum); // Set the hovered datum
                                        },
                                        onMouseOut: function () {
                                            setActiveBar(null); // Reset the hovered datum
                                        },
                                    },
                                }] })] })] }) }));
};
export { HeartRateActivity };
